
import Trade from "./Trade";
import data from '../data/banner.json';

export default function Banner() {

    return ( 
    <>
      {/* Banner @s */}
  <div className="header-banner bg-theme-grad-alt">
  <div className="nk-banner">
    <div className="banner banner-fs banner-single banner-with-token-box-s1">
      <div className="ui-shape ui-shape-header ui-shape-light" />
      <div className="banner-wrap">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-10 col-md-10 col-xl-9">
              <div className="banner-caption tc-light text-center">
                <div
                  className="cpn-title animated"
                  data-animate="fadeInUp"
                  data-delay="1.25"
                >
                  <h1 className="title title-xl-s2 title-semibold">
                    {" "}
                    SIGO - The World’s 1<sup>st</sup> Crypto Loyalty reward
                    token .{" "}
                  </h1>
                  <a href="/assets/BuySIGO.pdf" className="btn btn-light bg-white btn-lg rounded-pill" target="_blank"><img src="assets/double-right.gif" width={30}/>How to Buy SIGO TOKEN</a>
                </div>
                <div className="spacer" />
              </div>
            </div>
            {/* .col */}
          </div>
          {/* .row */}
        </div>
        <div className="banner-social-wrap text-center animated">
          <ul className="banner-social banner-social-vertical">
            <li>
              <a href="https://fb.me/Sigotokenofficial" target="_blank">
                <em className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/sigotoken" target="_blank">
                <em className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://youtube.com/channel/UCCyjd3s9TiSQk0lJoULh49w"
                target="_blank"
              >
                <em className="fab fa-youtube" />
              </a>
            </li>
            <li>
              <a href="https://t.me/sigotoken" target="_blank">
                <em className="fab fa-telegram" />
              </a>
            </li>
            <li>
              <a href="https://medium.com/@Sigotoken" target="_blank">
                <em className="fab fa-medium-m" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="nk-ovm mask-b mask-contain-bottom" />
  </div>
  {/* .nk-banner */}
  <div
    className="token-box token-box-s1 animated "
    data-animate="fadeInUp"
    data-delay="1.65"
  >
    <div className="container ">
      <div className="row  justify-content-center token-box-s12">
        <div className="col-xl-6 col-md-6 ">
        <Trade/>
        </div>
        {/* .col  */}
      </div>
      {/* .row  */}
    </div>
    {/* .container  */}
  </div>
  {/* Place Particle Js */}
  <div id="particles-bg" className="particles-container particles-bg" />
</div>
<div className="gap-5x" />
<div className="gap-10x d-md-none" />
<div className="gap-5x d-sm-none" />
{/* .header-banner @e */}
</>
    )
}

import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import Home from './pages/Home';
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { BrowserRouter as Router, Routes, Route, Link }from 'react-router-dom';
import "@fontsource/inter";
import Header from './components/inc/Header';
import Footer from './components/inc/Footer';

function App() {
  return (
     <>
   <Router>

   <Header/>
<Routes>
  {/* Components */}
<Route  path='/' element={<Home/>} ></Route> 
</Routes>
<Footer/>
  
   </Router>

       </>
   
  );
}

export default App;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';

import ReactDOM from "react-dom";
import AccountModal from '../AccountModal';
import ConnectButton from '../ConnectButton';
import { ChakraProvider, useDisclosure } from "@chakra-ui/react";

 function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
 

  return (
    
    <>
    <header
  className="nk-header page-header is-transparent is-sticky is-shrink"
  id="header"
>
  {/* Header @s */}
  <div className="header-main">
    <div className="header-container header-container-s1">
      <div className="header-wrap">
        {/* Logo @s */}
        <div
          className="header-logo logo animated"
          data-animate="fadeInDown"
          data-delay=".65"
        >
          <a href="./" className="logo-link">
            <img
              className="logo-dark"
              src="images/sigo-orange.png"
              alt="SIGO"
            />
            <img
              className="logo-light"
              src="images/sigo-white.png"
              alt="Sigo"
            />
          </a>
        </div>
        {/* Menu Toogle @s 
        <div className="header-nav-toggle">
          <a href="#" className="navbar-toggle" data-menu-toggle="header-menu">
            <div className="toggle-line">
              <span />
            </div>
          </a>
        </div>
        {/* Menu @s */}
         <div className='mobile-wallet'>
       <ChakraProvider>
          <ConnectButton handleOpenModal={onOpen} />
       <AccountModal isOpen={isOpen} onClose={onClose} /> 
           </ChakraProvider>
       </div>
        <div className="header-navbar header-navbar-s1">
          <nav className="header-menu justify-content-between" id="header-menu">
            <ul
              className="menu animated remove-animation"
              data-animate="fadeInDown"
              data-delay=".75"
            >
              <li className="menu-item">
                <a className="menu-link nav-link" onClick={() => window.location.replace("/#about")}>
                  About
                </a>
              </li>
              <li className="menu-item">
                <a className="menu-link nav-link"  onClick={() => window.location.replace("/#keyfeatures")}>
                  Key Features
                </a>
              </li>
              <li className="menu-item">
                <a className="menu-link nav-link" onClick={() => window.location.replace("/#ecosystem")}>
                  Ecosystem
                </a>
              </li>
              <li className="menu-item">
                <a className="menu-link nav-link"  onClick={() => window.location.replace("/#tokensale")}>
                  Tokenomics
                </a>
              </li>
              <li className="menu-item">
                <a className="menu-link nav-link" onClick={() => window.location.replace("/#roadmap")}>
                  Roadmap
                </a>
              </li>
              {/*<li class="menu-item"><a class="menu-link nav-link" href="#team">Team</a></li>*/}
              <li className="menu-item">
                <a className="menu-link nav-link" onClick={() => window.location.replace("/#faqs")}>
                  FAQ
                </a>
              </li>
              {/* <li class="menu-item"><a class="menu-link nav-link" href="#contact">Contact</a></li>*/}
            </ul>
            <ul
              className="menu-btns align-items-center animated remove-animation"
              data-animate="fadeInDown"
              data-delay=".85"
            >
              <li>
                <a target="_blank"
                  href="https://app.sigotoken.io"
                  className="btn btn-md btn-round btn-outline btn-auto btn-primary"
                >
                  <span>Investor Portfolio Login</span>
                </a>
              </li>
                 <li> <ChakraProvider>
          <ConnectButton handleOpenModal={onOpen} />
       <AccountModal isOpen={isOpen} onClose={onClose} /> 
           </ChakraProvider></li>
            </ul>
          </nav>
        </div>
        {/* .header-navbar @e */}
      </div>
    </div>
  </div>
  {/* .header-main @e */}
  
</header>




    </>
  )
}

export default Header


import { ChainId, DAppProvider, useEthers } from "@usedapp/core";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { JsonRpcSigner } from "@ethersproject/providers";
import App from "./App";

const config = {
  readOnlyUrls: {
    [ChainId.Mainnet]: "https://mainnet.infura.io/v3",
    [ChainId.BSC]: "https://bsc-dataseed1.binance.org",
    [ChainId.Polygon]: "https://polygon-rpc.com"  
  },
  notifications: {
    checkInterval: 500,
    expirationPeriod: 50000,
  },
};

function PollPage() {
  const { account, library, activateBrowserWallet, deactivate} = useEthers();
  const [signer, setSigner] = useState<undefined | JsonRpcSigner>(undefined);

  useEffect(() => {
  //  console.log(ChainId);
    if (account) {
     // setSigner(library?.getSigner());

     //console.log(library);
    } else {
      // Deactivate signer if signed out
      setSigner(undefined);
    }
  }, [account]);

  return (
    <div>
    </div>
  );
}

function AppPage() {
  return (
      <DAppProvider config={config}>
        <PollPage/>
        <App/>
      </DAppProvider>
  );
}


ReactDOM.render(
  <React.StrictMode>
    <AppPage />
  </React.StrictMode>,
  document.getElementById("root")
);
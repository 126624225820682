
import { useEffect, useState } from "react";
import { Alert,AlertIcon,AlertDescription,useToast } from "@chakra-ui/react";
import { useSendTransaction,useEthers,useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { utils } from 'ethers'
import axios from "axios";
import Web3 from 'web3';
import Common from '@ethereumjs/common';
import Contract_ABI from '../../abi/ETHContract.json';
import { AbiItem } from 'web3-utils';
const ETH = (props:any) => {
  const { account } = useEthers();
  const [isLoading, setLoading] = useState(false);
  const { sendTransaction, state :buyTx } = useSendTransaction({ transactionName: 'Buy' });
  const [TokenMaticPrice,setTokenPrice] =useState<number | undefined>();
  const [MaticValue,setMaticValue] =useState<number | undefined>();
  const [SIGOValue,setSIGOValue] =useState<number | undefined>();
  const toast = useToast()
  const [balance, setBalance] = useState('');
  const API_URL="";
  
  useEffect(()=>{
    getBalance()
    fetch('https://www.binance.com/api/v3/ticker/price?symbol=ETHUSDT').then(res => res.json()).then(res =>{
      setTokenPrice(1/res.price);
    })
  },[SIGOValue])

  useEffect(() => {
    
    const userdata ={
      "amount" : Number(MaticValue),
      "blockHash" : "33",
      "hash"  : "dff",
      "from"  : account,
      'to'    : "d",
      "tokens" : 1.00,
    }
     sendSIGOToken(userdata);
    // eslint-disable-next-line eqeqeq
       if(buyTx.errorMessage){
   
        alert(buyTx.errorMessage)
         setLoading(false);
       }
     
         if(buyTx.status!=="None" && buyTx.receipt){
          
         let trx=buyTx.receipt;
         console.log(trx);
         alert("Succefully! Buy ɴ₿TC Transaction");
         
       
       }
   
     }, [buyTx]);
   
const handleSIGO =(e: any) =>{
  setMaticValue(Number(TokenMaticPrice)*e);
  setSIGOValue(e);

}


const handleMatic =(e: any) =>{
  setMaticValue(e)
  setSIGOValue((1/Number(TokenMaticPrice))*e);
 console.log((1/Number(TokenMaticPrice))*e);
}


const sendSIGOToken = async(userdata: { amount: number; to: any; from : any; tokens: any; }) => {
  var web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3'));
  // Who holds the token now?
  var myAddress = "0x5Cc06Dfc96f7de40e51F2938a2396513993a0025";
  // Who are we trying to send this token to?
  var toAddress = userdata.from;
  // If your token is divisible to 8 decimal places, 42 = 0.00000042 of your token
  // Determine the nonce

   var amountParent = web3.utils.toHex(userdata.tokens*1000000000);
   
   var privateKey = Buffer.from("f55761be856859bdcc3a0a93a663a571871c6852d39f4e15776a6f88796ae8ee", 'hex');
  var contractAddress = '0xd05882790242FA0ff1B8e6B735f7428e420399da'; // ONLYONE address

  
  var contract = new web3.eth.Contract(Contract_ABI as unknown as AbiItem[], contractAddress, {from: myAddress});
  var BSC_FORK = Common.forCustomChain('mainnet',
  {
    name: 'mainnet',
    networkId: 1,
    chainId: 1,
    url: 'https://mainnet.infura.io/v3'
  },
  'petersburg');

  var count = await web3.eth.getTransactionCount(myAddress);
  console.log(count);
  var rawTransaction = {
      "from":myAddress,
      "gasPrice":web3.utils.toHex(35000000000),
      "gasLimit":web3.utils.toHex(210000),
      "to":contractAddress,"value":"0x0",
      "data":contract.methods.transfer(toAddress, amountParent).encodeABI(),
      "nonce": web3.utils.toHex(count)
  };
  var Tx = require('ethereumjs-tx').Transaction;
  var transaction = new Tx(rawTransaction, {'common':BSC_FORK});
  transaction.sign(privateKey);
 
  var result = await web3.eth.sendSignedTransaction('0x' + transaction.serialize().toString('hex'));
   console.log(result); 
    // Handle the response from backend here
   
}

const sendTrans = async (numb: number) => {
  const receipt = await sendTransaction({ to: "0x5Cc06Dfc96f7de40e51F2938a2396513993a0025", value: utils.parseEther(numb.toString()) });
}


async function getBalance(){
     
  var contractAddress = '0xd05882790242FA0ff1B8e6B735f7428e420399da'; // ONLYONE address
  
  // just the `balanceOf()` is sufficient in this case
  var web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3'));
     
  
  const contract = new web3.eth.Contract(Contract_ABI as AbiItem[], contractAddress);
  const balance = await contract.methods.balanceOf(account).call();
  
  // note that this number includes the decimal places (in case of BUSD, that's 18 decimal places)
  setBalance(balance);
  return balance;
   }

const handleSubmit  = (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  if(Number(SIGOValue)  < 0.01){
   alert('Please enter atleast minimum 1 SIGO');
   return false;
  }
  sendTrans(Number(MaticValue));
  //setLoading(true);
};


return (
    <>
        <form onSubmit={handleSubmit}>
          
    <div className="row blue mt-3 align-items-center">
  
        <div className="col-9 form-floating">
            <input
              type="number"
              className="form-control text-white border-bottom"
              id="floatingInputValue"
              placeholder=""
              required
              autoComplete="off"
              value={(MaticValue)? MaticValue :''}
              defaultValue={TokenMaticPrice?.toFixed(6)}
              onChange={(e) => handleMatic(e.target.value)}
            />
            <label htmlFor="floatingInputValue" className='text-white'>You Send</label>
         
        </div>
        <div className="col-3 p-2 text-center">
          <img
            src="images/ETH.png"
            className='img-fluid rounded-pill m-auto' width={40}
          />
            <small className="text-white">{props.Balance && parseFloat(formatEther(props.Balance)).toFixed(3)} ETH</small>
   
        </div>

       
      </div>
      <span className="progress-info">
        Estimated rate: <span>1 SIGO ~ {TokenMaticPrice?.toFixed(6)} ETH</span>
      </span>
      <div className="row blue align-items-center">
        <div className="col-9 form-floating ">
            <input
              type="number"
              className="form-control text-white border-bottom"
              id="floatingInputValue"
              placeholder=""
              autoComplete="off"
              inputMode="decimal"
              defaultValue="1"
              required
              value={(SIGOValue)? SIGOValue : ''}
              onChange={(e) => handleSIGO(e.target.value)}
            />
            <label htmlFor="floatingInputValue" className='text-white'>You Recieve</label>
         </div>
        <div className="col-3 p-2 text-center ">
        
          <img
            src="images/sigo.png"
            className='img-fluid rounded-pill m-auto' width={40}
          />
              <small className="text-white">{balance && Number(balance)/ Math.pow(10, 9)} SIGO</small>
   
        </div>
      </div>
      <div className="col-12 text-center">
        <button type="submit"
          className="btn btn-grad rounded w-100 top mt-4 text-center"
        >
          Buy Token 
        </button>
       
      </div>
      </form>
      </>
)
}

export default ETH;
import { Button, Box, Text } from "@chakra-ui/react";
import { useEthers,useEtherBalance, ChainId} from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";
import Select from "react-dropdown-select";
import { useEffect, Component } from "react";
import { Alert,AlertIcon,AlertDescription,useToast } from "@chakra-ui/react";
type Props = {
  handleOpenModal: any;
};


export default function ConnectButton({ handleOpenModal }: Props )  {
  const toast = useToast()
  const { activateBrowserWallet, account,library, chainId } = useEthers();
  const etherBalance = useEtherBalance(account);
  
  
   function handleConnectWallet() {
     //const chainname= library?._network.name;
      activateBrowserWallet();
    
  /*  if(chainId==undefined ){
  
 toast({
  title: 'Oops!',
  position: 'top-right',
  description: 'Support Network Only : Binance, Polygon,Tron and Etherium.',
  status: 'error',
  duration: 10000,
  isClosable: true,

})
     
      }else{

      toast({
        title: 'Wow!',
        position: 'top-right',
        description: 'Connected to Binance Chain Network.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      
      })
      
      
    
     }
    */
  
  }
  const network= "" ;

  return account ? (
    <>
       {network}
            
              
              
    <Box
      display="flex"
      alignItems="center"
       className="wallet-btn rounded-pill px-3"
      borderRadius="xl"
      py="0"
    >
     
      <Button
        onClick={handleOpenModal} 
             className="btn btn-md btn-round btn-auto wallet-border text-white"
      >
        
        <Identicon />
        <Text  fontSize="md" fontWeight="medium" ml="2">
          {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 3,
              account.length
            )}`}
        </Text>
      </Button>
    </Box>
    
    </>
  ) : (
    <>
   

     <Button
    onClick={handleConnectWallet}
    className="btn btn-md btn-round btn-auto wallet-border text-dark"
   
   >
    Connect Wallet
  </Button>
   
   
   
   </>
  );
}

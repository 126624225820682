import { useEffect, useState } from 'react';
import { useEthers,useEtherBalance } from "@usedapp/core";
import ETH from './Trade/ETH';
import BSC from './Trade/BSC';
import MATIC from './Trade/MATIC';


export default function Trade() {
    const { account,chainId } = useEthers();
  const etherBalance = useEtherBalance(account);
  return (  <div className="token-countdown-wrapper split split-left split-md-left">
  <h5 className="title-sm text-center"><strong>SIGO COIN</strong></h5>
    {chainId==undefined ? <BSC Balance={0x0000} /> :""}
    {chainId==1? <ETH Balance={etherBalance}/> : ""}
    {chainId==56? <BSC Balance={etherBalance} /> : ""}
    {chainId==137? <MATIC Balance={etherBalance}/> : ""}

</div>)
}
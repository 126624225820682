import React from 'react'

function Footer() {
  return (

      <>
    <footer className="nk-footer bg-theme-grad-alt">
  <section className="section section-l section-footer pb-0 tc-light tc-light-alt bg-transparent ov-h">
    <div className="ui-shape ui-shape-footer ui-shape-light" />
    <div className="container">
      {/* Block @s */}
      <div className="nk-block block-footer">
        <div className="row my-md-0 mb-n4">
          <div className="col-lg-4 col-sm-4">
            <div className="wgs wgs-menu">
              <h6
                className="wgs-title wgs-title-s2 text-white animated"
                data-animate="fadeInUp"
                data-delay=".3"
              >
                RESOURCES
              </h6>
              <div className="wgs-body">
                <ul
                  className="wgs-links wgs-links-uline wgs-links-s2 animated"
                  data-animate="fadeInUp"
                  data-delay=".4"
                >
                  <li>
                    <a href="#about">About Sigo</a>
                  </li>
                  <li>
                    <a href="#faqs">FAQ</a>
                  </li>
                  <li>
                    <a href="#keyfeatures">KEY Features</a>
                  </li>
                  <li>
                    <a href="#contact">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* .col */}
          <div className="col-lg-4 col-sm-4">
            <div className="wgs wgs-menu">
              <h6
                className="wgs-title wgs-title-s2 text-white animated"
                data-animate="fadeInUp"
                data-delay=".5"
              >
                COMPANY
              </h6>
              <div className="wgs-body">
                <ul
                  className="wgs-links wgs-links-uline wgs-links-s2 animated"
                  data-animate="fadeInUp"
                  data-delay=".6"
                >
                  <li>
                    <a href="#roadmap">Roadmap</a>
                  </li>
                  <li>
                    <a href="#">Whitepaper</a>
                  </li>
                  <li>
                    <a href="#ecosystem">Ecosystem</a>
                  </li>
                  <li>
                    <a href="#tokensale">Tokenomics</a>
                  </li>
                  <li>
                    <a href="#">Privacy &amp; policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* .col */}
          <div className="col-lg-4">
            <div className="wgs wgs-subscribe-form">
              <h6
                className="wgs-title wgs-title-s2 text-white animated"
                data-animate="fadeInUp"
                data-delay=".7"
              >
                NewsLetter
              </h6>
              <div className="wgs-body">
                <div className="wgs-subscribe">
                  <p>
                    Subscribe to get latest updates &amp; announcement on SIGO.
                  </p>
                  <form
                    action="form/subscribe.php"
                    className="nk-form-submit"
                    method="post"
                  >
                    <div
                      className="field-inline field-inline-s3 field-split animated"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <div className="field-wrap">
                        <input
                          className="input-bordered required"
                          placeholder="Enter your email"
                          type="text"
                        />{" "}
                        <input
                          className="d-none"
                          name="form-anti-honeypot"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                      <div className="submit-wrap">
                        <button className="btn btn-SIGOn btn-light btn-auto" > <i className='fa fa-envelope text-dark'></i></button>
                      </div>
                    </div>
                    <div className="form-results" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
        <div className="footer-bottom section-sm">
          <div className="row justify-content-between align-items-center">
            <div className="col-6">
              <a className="wgs-logo-s2" href="./">
                <img alt="logo" id="footer-logo" src="images/sigo-white.png" />{" "}
              </a>
            </div>
            {/* .col */}
            <div className="col-6">
              <div className="copyright-text text-end">
                <p>© SIGO Crypto, 2022</p>
              </div>
            </div>
            {/* .col */}
          </div>
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
  </section>
</footer>


    </>
  )
}

export default Footer
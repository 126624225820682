import { useEffect, useState } from "react";
import { useSendTransaction,useEthers,useEtherBalance } from "@usedapp/core";
import { Button, Box, Text } from "@chakra-ui/react";
import { formatEther } from "@ethersproject/units";
import { utils } from 'ethers'
import axios from "axios";
import Web3 from 'web3';
import Common from '@ethereumjs/common';
import Contract_ABI from '../../abi/BSCContract.json';
import { AbiItem } from 'web3-utils';
const BSC = (props:any) => {
  const { account, activateBrowserWallet } = useEthers();
  const [isLoading, setLoading] = useState(false);
  const { sendTransaction, state :buyTx } = useSendTransaction({ transactionName: 'Buy' });
  const [TokenBSCPrice,setTokenPrice] =useState<number | undefined>();
  const [TokenSellBSCPrice,setSellTokenPrice] =useState<number | undefined>();
  
  const [BSCValue,setBSCValue] =useState<number | undefined>();
  const [SIGOValue,setSIGOValue] =useState<number | undefined>();
  const [SellBSCValue,setSellBSCValue] =useState<number | undefined>();
  const [SellSIGOValue,setSellSIGOValue] =useState<number | undefined>();
  const [BuyMinToken,setBuyMinToken] =useState<number | undefined>();
  const [sellaction,setsellaction] =useState<number>();
  const [errors, setErrors] = useState('');
  const [balance, setBalance] = useState('');
  const API_URL="https://admin.sigotoken.io/api";
  const privateKeyM="afc7ec9ab8d901f44a49db356aaf405ceebd6e0370e661bd209e98e64508803d";
  const contractAddress= "0x37d66Ec297Ac1E99c99b406e652ad4775511F646";
  const myAddress = "0xB3361346C924E293Bb4b8124314eF6247713843E";
  const ChainRPC = "https://bsc-dataseed1.binance.org";
  function handleConnectWallet() {
    //const chainname= library?._network.name;
     activateBrowserWallet();
  
 
 }
 useEffect(()=>{
    //console.log(TokenSellBSCPrice);
    fetch(API_URL+'/rounds').then(res => res.json()).then(res =>{
      setsellaction(res[0].sell_action);
     
    })
    getBalance()
    fetch('https://www.binance.com/api/v3/ticker/price?symbol=BNBUSDT').then(res => res.json()).then(res =>{
      fetch(API_URL+'/rounds').then(ress => ress.json()).then(ress =>{
        setTokenPrice(ress[0].token_price/res.price);
        setSellTokenPrice(ress[0].sell_token_price/res.price);
        setBuyMinToken(ress[0].buy_token_min);
      })
     })
  },[TokenBSCPrice,TokenSellBSCPrice,sellaction]);

  useEffect(() => {
    
    // eslint-disable-next-line eqeqeq
    if(buyTx.errorMessage){

      setErrors(buyTx.errorMessage);
      setLoading(false);
    }
     
       if(buyTx.status!=="None" && buyTx.receipt){
       
        let trx=buyTx.receipt;
         const userdata ={
          "amount" : Number(TokenBSCPrice),
          "blockHash" : trx?.blockHash,
          "hash"  : trx?.transactionHash,
          "from"  : trx?.from,
          'to'    : trx?.to,
          "tokens" : SIGOValue,
          "blockchain" : "BNB",
          "status" : buyTx?.status,
           "confirmations"  : trx?.confirmations
        }
        console.log(userdata);
        axios({
          // Endpoint to send files
          url: API_URL+"/register",
          method: "POST",
           // Attaching the form data
          data: userdata,
        })  
          // Handle the response from backend here
          .then((res) => {
            
            sendSIGOToken(userdata);
              setErrors("Succefully! Buy Sigo Transaction");
             setTimeout(() => {
              setLoading(false);
            }, 14000);
            setTimeout(() => {
              window.location.reload();
  
            }, 20000);
           })
      
          // Catch errors if any
          .catch((err) => { 
             });
      }
   
     }, [buyTx]);
   



const handleSellBSC =(e: any) =>{
  setBSCValue(e)
  setSIGOValue((1/Number(TokenBSCPrice?.toFixed(3)))*e);
 console.log((1/Number(TokenBSCPrice))*e);
}
const handleSellSIGO =(e: any) =>{
  setSellBSCValue(Number(TokenSellBSCPrice?.toFixed(6))*e);
  setSellSIGOValue(e);

}


const handleBSC =(e: any) =>{
  setBSCValue(e)
  setSIGOValue((1/Number(TokenBSCPrice?.toFixed(3)))*e);
}
const handleSIGO =(e: any) =>{
  setBSCValue(Number(TokenBSCPrice?.toFixed(6))*e);
  setSIGOValue(e);

}


const sendSIGOToken = async(userdata: { amount: number; to: any; from : any; tokens: any; }) => {
  var web3 = new Web3(new Web3.providers.HttpProvider(ChainRPC));
  // Who holds the token now?
  // Who are we trying to send this token to?
  var toAddress = userdata.from;
  // If your token is divisible to 8 decimal places, 42 = 0.00000042 of your token
  // Determine the nonce
  var amountParent = web3.utils.toHex(userdata.tokens*100000000);
  console.log(userdata.tokens);
   var privateKey = Buffer.from(privateKeyM, 'hex');
 
  var contract = new web3.eth.Contract(Contract_ABI as unknown as AbiItem[], contractAddress, {from: myAddress});
  var BSC_FORK = Common.forCustomChain(
    'mainnet',
    {
    name: 'Binance Smart Chain Mainnet',
    networkId: 56,
    chainId: 56,
    url: 'https://bsc-dataseed.binance.org/'
    },
    'istanbul',
);

  var count = await web3.eth.getTransactionCount(myAddress);

  var rawTransaction = {
      "from":myAddress,
      "gasPrice":web3.utils.toHex(5000000000),
      "gasLimit":web3.utils.toHex(210000),
     "to":contractAddress,"value":"0x0",
      "data":contract.methods.transfer(toAddress, amountParent).encodeABI(),
      "nonce":web3.utils.toHex(count)
  };
  var Tx = require('ethereumjs-tx').Transaction;
  var transaction = new Tx(rawTransaction, {'common':BSC_FORK});
  transaction.sign(privateKey);
 
  var result = await web3.eth.sendSignedTransaction('0x' + transaction.serialize().toString('hex'));
  axios({
    // Endpoint to send files
    url: API_URL+"/send_token",
    method: "POST",
     // Attaching the form data
     data: {
       "blockHash" :result.blockHash,
       "hash" : result.transactionHash,
       "from": result.from,
       "to": userdata.to,
       "status": "Success",
       "amount" : userdata.amount,
       "total_token": userdata.tokens,
       "type" : "buy_token",
       "blockchain" : "BNB",

     }
   })  
    // Handle the response from backend here
    .then((res) => { 
      setErrors(res.data.msg);

    })

    // Catch errors if any
    .catch((err) => { 
      console.log(err.response);
    });
    // Handle the response from backend here
   
}

const sendTrans = async (numb: number) => {
  const receipt = await sendTransaction({ to: myAddress, value: utils.parseEther(numb.toString()) });
}


async function getBalance(){
     
  
  // just the `balanceOf()` is sufficient in this case
  var web3 = new Web3(new Web3.providers.HttpProvider(ChainRPC));
     
  
  const contract = new web3.eth.Contract(Contract_ABI as AbiItem[], contractAddress);
  const balance = await contract.methods.balanceOf(account).call();
  
  // note that this number includes the decimal places (in case of BUSD, that's 18 decimal places)
  setBalance(balance);
  return balance;
   }

   const sendBNBToken = async(tokens: number) => {
    var web3 = new Web3(new Web3.providers.HttpProvider(ChainRPC));
         
       var decimals = 18;
  var value = (tokens*(10**decimals)).toString();
  var amountParent = web3.utils.toBN(value);
    var privateKey = Buffer.from(privateKeyM, 'hex');
    var BSC_FORK = Common.forCustomChain(
        'mainnet',
        {
        name: 'Binance Smart Chain Mainnet',
        networkId: 56,
        chainId: 56,
        url: 'https://bsc-dataseed.binance.org/'
        },
        'istanbul',
    );
  
    var count = await web3.eth.getTransactionCount(myAddress);
  
    var rawTransaction = {
        "from":myAddress,
        "gasPrice":web3.utils.toHex(5000000000),
        "gasLimit":web3.utils.toHex(210000),
        "to":account?.toString(),
        "value":amountParent,
        "nonce":web3.utils.toHex(count)
    };
    var Tx = require('ethereumjs-tx').Transaction;
    var transaction = new Tx(rawTransaction, {'common':BSC_FORK});
    transaction.sign(privateKey);
   
    var result = await web3.eth.sendSignedTransaction('0x' + transaction.serialize().toString('hex'));
    axios({
      // Endpoint to send files
      url: API_URL+"/send_token",
      method: "POST",
       // Attaching the form data
       data: {
         "blockHash" :result.blockHash,
         "hash" : result.transactionHash,
         "from": result.from,
         "to": account,
         "status": "Success",
         "amount" : SellBSCValue,
         "total_token": tokens,
         "type" : "sell_token",
         "blockchain" : "BNB",
  
       }
     })  
      // Handle the response from backend here
      .then((res) => { 
        setErrors("Token Sell Succussfully & BNB received");
  
      })
  
      // Catch errors if any
      .catch((err) => { 
        console.log(err.response);
      });
    
  
  }
  
   const sellTrans = async (numb: number) => {
  
    var web3 = new Web3(new Web3.providers.HttpProvider(ChainRPC));
    var amountParent = web3.utils.toHex(numb*100000000);
  
    var contract = new web3.eth.Contract(Contract_ABI as unknown as AbiItem[], contractAddress);
  
    const transfer = await contract.methods.transfer(myAddress, amountParent);
    const encodedABI = await transfer.encodeABI();
    var count = await web3.eth.getTransactionCount(myAddress);
  
    var rawTransaction = {
    
        "from":account?.toString(),
        "gasPrice":web3.utils.toHex(5000000000),
        "gasLimit":web3.utils.toHex(210000),
        "to":contractAddress,"value":"0x0",
        "data":encodedABI,
        "nonce":web3.utils.toHex(count)
    
      };
  
    const receipt = await sendTransaction(rawTransaction);
     
    var tokens = (Number(TokenSellBSCPrice)/1) * Number(numb);
    sendBNBToken(tokens);
      setLoading(false);
  
      setTimeout(() => {
         window.location.reload();
      }, 2000);
  
  }
  

const handleSubmit  = (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  if(Number(SIGOValue)  < Number(BuyMinToken)){
   setErrors('Please enter atleast minimum '+Number(BuyMinToken)+' SIGO');
   return false;
  }
  sendTrans(Number(BSCValue));
  setLoading(true);
 };


const handleSellSubmit  = (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  let tokenamont= Number(SellSIGOValue);
  if(tokenamont  < 100){
   setErrors("Please enter minimum amount 100 Sigo Token");
    return false;
  }
  setLoading(true);
  sellTrans(tokenamont);

};


return (
    <>  
    {
          isLoading &&
          <div className='loader-box'>
            
            <div className="loading">
      <div></div>
      <div></div>
    </div>  
             </div>
        }
     {errors && <div className="alert alert-danger  mb-3 text-center">{errors}</div>} 
     <ul className="nav nav-tabs" id="myTab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        className="nav-link active btn btn-primary nowrap h-v"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#home-tab-pane"
        type="button"
        role="tab"
        aria-controls="home-tab-pane"
        aria-selected="true"
      >
        Buy Token
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className="nav-link btn btn-secondary  nowrap"
        id="profile-tab"
        data-bs-toggle="tab"
        data-bs-target={(sellaction==1)? "#profile-tab-pane": "" }
        type="button"
        role="tab"
        aria-controls="profile-tab-pane"
        aria-selected="false"
      >
        Sell Token {(sellaction==0)? <span className="badge badge-warning">inactive</span>: ""}
      </button>
    </li> 
  </ul>
  <div className="tab-content" id="myTabContent">
  
    <div
      className="tab-pane fade show active"
      id="home-tab-pane"
      role="tabpanel"
      aria-labelledby="home-tab"
      tabIndex={0}
    >
         <form onSubmit={handleSubmit}  className="form-floating">
       
      <div className="row blue">
        <div className="col-7">
        <label htmlFor="floatingInputValue" className="text-white">You Send</label>
        
            <input
              type="number"
              className="form-control text-white"
              id="floatingInputValue"
              placeholder=""
              required
              autoComplete="off"
              value={(BSCValue)? BSCValue :''}
              defaultValue={TokenBSCPrice?.toFixed(6)}
              onChange={(e) => handleBSC(e.target.value)}
            />
        </div>
        <div className="col-3 ">
          <button type="button" className="btn btn border-none">
            
          <span className="text-white">{props.Balance && parseFloat(formatEther(props.Balance)).toFixed(3)} BNB</span>
          </button>
        </div>
        <div className="col-2">
          <button
            className="btn btn border-none margin-left icons"
          >
             <img
            src="images/Binance.png"
            className=' icon' width={40}
          />
          </button>
        </div>
      </div>
      <span className="progress-info">
      Estimated rate: <span>1 SIGO ~ {TokenBSCPrice?.toFixed(6)} BNB</span>
      </span>
      <div className="row blue">
        
        <div className="col-7">
        <label htmlFor="floatingInputValue" className="text-white">You Recieve</label>
     
            <input
              type="number"
              className="form-control text-white"
              id="floatingInputValue"
              placeholder=""
              autoComplete="off"
              inputMode="decimal"
              defaultValue="1"
              required
              value={(SIGOValue)? SIGOValue : ''}
              onChange={(e) => handleSIGO(e.target.value)}
            />
        </div>
        <div className="col-3 ">
          <button type="button" className="btn btn border-none">
          <span className="text-white">{balance && Number(balance)/ Math.pow(10, 8)} SIGO</span>
          </button>
        </div>

      
        <div className="col-2">
          <button
            className="btn btn border-none margin-left icons"
          >
            <img
            src="images/sigo.png"
            className='icon' 
          />
          </button>
        </div>
      </div>
      {(account) ? <button type="submit"
         className="btn btn-grad top w-100 mx-auto"
        >
        Submit Now
       </button> :  <Button
    onClick={handleConnectWallet}
    className="btn btn-gr text-dark rounded-pill fw-bold  top w-100 mx-auto"
   
   >
    Connect Wallet
  </Button> }
      
       </form>
    </div>
    <div
      className="tab-pane fade"
      id="profile-tab-pane"
      role="tabpanel"
      aria-labelledby="profile-tab"
      tabIndex={0}
    >
         <form onSubmit={handleSellSubmit}  className="form-floating">
       
         <div className="row blue">
        
        <div className="col-7">
        <label htmlFor="floatingInputValue" className="text-white">You Send</label>
     
            <input
              type="number"
              className="form-control text-white"
              id="floatingInputValue"
              placeholder=""
              autoComplete="off"
              inputMode="decimal"
              defaultValue="1"
              required
              value={(SellSIGOValue)? SellSIGOValue : ''}
              onChange={(e) => handleSellSIGO(e.target.value)}
            />
        </div>
        <div className="col-3 ">
          <button type="button" className="btn btn border-none">
          <span className="text-white">{balance && Number(balance)/ Math.pow(10, 9)} SIGO</span>
          </button>
        </div>

      
        <div className="col-2">
          <button
            className="btn btn border-none margin-left icons"
          >
            <img
            src="images/sigo.png"
            className='icon' 
          />
          </button>
        </div>
      </div>
      <span className="progress-info">
      Estimated rate: <span>1 SIGO ~ {TokenSellBSCPrice?.toFixed(6)} BNB</span>
      </span>
      <div className="row blue">
        <div className="col-7">
        <label htmlFor="floatingInputValue" className="text-white">You Receive</label>
        
            <input
              type="number"
              className="form-control text-white"
              id="floatingInputValue"
              placeholder=""
              required
              autoComplete="off"
              value={(SellBSCValue)? SellBSCValue :''}
              defaultValue={TokenBSCPrice?.toFixed(3)}
              onChange={(e) => handleSellBSC(e.target.value)}
            />
        </div>
        <div className="col-3 ">
          <button type="button" className="btn btn border-none">
            
          <span className="text-white">{props.Balance && parseFloat(formatEther(props.Balance)).toFixed(3)} BNB</span>
          </button>
        </div>
        <div className="col-2">
          <button
            className="btn btn border-none margin-left icons"
          >
             <img
            src="images/Binance.png"
            className=' icon' width={40}
          />
          </button>
        </div>
      </div>
   
      <button type="submit"
         className="btn btn-grad top"
        >
        Sigo Tokens &amp; Sell Now
       </button>
       </form>
    </div>
  </div>
        
      </>
)
}

export default BSC;
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSendTransaction,useEthers,useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { utils } from 'ethers'
import axios from "axios";
import Web3 from 'web3';
import Common from '@ethereumjs/common';
import Contract_ABI from '../abi/BSCContract.json';
import { AbiItem } from 'web3-utils';
import Banner from '../components/Banner';
function Home() {
  const fs = require('fs');
  const params = new URLSearchParams(window.location.search);
 
  const { account } = useEthers();
  const etherBalance = useEtherBalance(account);
  const [balance, setBalance] = useState('');
  
  async function getBalance(){
     
  var contractAddress = '0xcd4e2c1D422Df97da24e915DF9d906564C9613Bb'; // ONLYONE address
  
  // just the `balanceOf()` is sufficient in this case
  var web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
     
  
  const contract = new web3.eth.Contract(Contract_ABI as AbiItem[], contractAddress);
  const balance = await contract.methods.balanceOf(account).call();
  
  // note that this number includes the decimal places (in case of BUSD, that's 18 decimal places)
  setBalance(balance);
  return balance;
   }
  
  if(account){
    getBalance();
  } 

  const [amount, setAmount] = useState([]);
  const [errors, setErrors] = useState('');
  const [buyprice, setbuyprice] = useState<any[]>([]);
  const [sellprice, setsellprice] = useState<any[]>([]);
  const [BNBUSDT, setBNBUSDT] = useState<any[]>([]);
  
  const [sellaction, setsellaction] = useState<any[]>([]);
  
  const [isLoading, setLoading] = useState(false);
  const { sendTransaction, state :buyTx } = useSendTransaction({ transactionName: 'Buy' });
  const [copied, setCopied] = useState(false);
  const API_URL= "https://app.nbtc.exchange/api";

  function copyrefferal() {
    const el = document.createElement("input");
    el.value ='https://loc.io?refferal='+account;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    alert("Refferal Link Copied!");
  }

    useEffect(() => {
 // eslint-disable-next-line eqeqeq
 get_current_round();
 get_live_bnb_usdt();
    if(buyTx.errorMessage){

      setErrors(buyTx.errorMessage);
      setLoading(false);
    }
  
      if(buyTx.status!=="None" && buyTx.receipt){
       
      let trx=buyTx.receipt;
       const userdata ={
        "amount" : Number(amount),
        "blockHash" : trx?.blockHash,
        "hash"  : trx?.transactionHash,
        "from"  : trx?.from,
        'to'    : trx?.to,
        "tokens" : (1/Number(buyprice))*Number(amount),
        "status" : buyTx?.status,
         "confirmations"  : trx?.confirmations
      }
      axios({
        // Endpoint to send files
        url: API_URL+"/register",
        method: "POST",
         // Attaching the form data
        data: userdata,
      })  
        // Handle the response from backend here
        .then((res) => {
          
            sendcazhToken(userdata);
            setErrors("Succefully! Buy ɴ₿TC Transaction");
           setTimeout(() => {
            setLoading(false);
          }, 14000);
          setTimeout(() => {
            window.location.reload();

          }, 20000);
         })
    
        // Catch errors if any
        .catch((err) => { });
    }

  }, [buyTx]);

  
  const get_current_round = () => {
    axios({
      // Endpoint to send files
      url: API_URL+"/get_current_round",
      method: "GET",
       // Attaching the form data
     })  
      // Handle the response from backend here
      .then((res) => { 
        console.log(res.data[0].sell_action);
        setbuyprice(res.data[0].bnb_token_price);
        setsellprice(res.data[0].token_price);
        setsellaction(res.data[0].sell_action);
      })
  
      // Catch errors if any
      .catch((err) => { });
  }


  const get_live_bnb_usdt = () => {
    axios({
      // Endpoint to send files
      url: "https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT",
      method: "GET",
       // Attaching the form data
     })  
      // Handle the response from backend here
      .then((res) => { 
        console.log(res.data);
        setBNBUSDT(res.data.price)
      })
  
      // Catch errors if any
      .catch((err) => { });
  }

  
const sendcazhToken = async(userdata: { amount: number; to: any; from : any; tokens: any; }) => {
    var web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
         
    // Who holds the token now?
    var myAddress = "0x859EfEDaA0BF5840d675FBe5a7236Af0AEB83Ba2";
    // Who are we trying to send this token to?
    var toAddress = userdata.from;
    // If your token is divisible to 8 decimal places, 42 = 0.00000042 of your token
    // Determine the nonce

     var amountParent = web3.utils.toHex(userdata.tokens.toFixed(2)*100000000);
    var privateKey = Buffer.from("048d9834e36554d33c47b31502e5d7f9883c930d166f14fa1bcf7cfe1ced7918", 'hex');
    var contractAddress = '0xcd4e2c1D422Df97da24e915DF9d906564C9613Bb'; // ONLYONE address

    
    var contract = new web3.eth.Contract(Contract_ABI as unknown as AbiItem[], contractAddress, {from: myAddress});
  
    var BSC_FORK = Common.forCustomChain(
        'mainnet',
        {
        name: 'Binance Smart Chain Mainnet',
        networkId: 56,
        chainId: 56,
        url: 'https://bsc-dataseed.binance.org/'
        },
        'istanbul',
    );

    var count = await web3.eth.getTransactionCount(myAddress);
  
    var rawTransaction = {
        "from":myAddress,
        "gasPrice":web3.utils.toHex(5000000000),
        "gasLimit":web3.utils.toHex(210000),
        "to":contractAddress,"value":"0x0",
        "data":contract.methods.transfer(toAddress, amountParent).encodeABI(),
        "nonce":web3.utils.toHex(count)
    };
    var Tx = require('ethereumjs-tx').Transaction;
    var transaction = new Tx(rawTransaction, {'common':BSC_FORK});
    transaction.sign(privateKey);
   
    var result = await web3.eth.sendSignedTransaction('0x' + transaction.serialize().toString('hex'));

    axios({
      // Endpoint to send files
      url: API_URL+"/send_token",
      method: "POST",
       // Attaching the form data
       data: {
         "blockHash" :result.blockHash,
         "hash" : result.transactionHash,
         "from": result.from,
         "to": userdata.to,
         "status": "Success",
         "amount" : userdata.amount,
         "total_token": userdata.tokens,
         "type" : "buy_token"
  
       }
     })  
      // Handle the response from backend here
      .then((res) => { 
        setErrors(res.data.msg);
  
      })
  
      // Catch errors if any
      .catch((err) => { 
        console.log(err.response);
      });
  }

  const sendBNBToken = async(tokens: number) => {
    var web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
         
    // Who holds the token now?
    var myAddress = "0x859EfEDaA0BF5840d675FBe5a7236Af0AEB83Ba2";
       var decimals = 18;
var value = (tokens*(10**decimals)).toString();
var amountParent = web3.utils.toBN(value);
    var privateKey = Buffer.from("048d9834e36554d33c47b31502e5d7f9883c930d166f14fa1bcf7cfe1ced7918", 'hex');
    var BSC_FORK = Common.forCustomChain(
        'mainnet',
        {
        name: 'Binance Smart Chain Mainnet',
        networkId: 56,
        chainId: 56,
        url: 'https://bsc-dataseed.binance.org/'
        },
        'istanbul',
    );

    var count = await web3.eth.getTransactionCount(myAddress);
  
    var rawTransaction = {
        "from":myAddress,
        "gasPrice":web3.utils.toHex(5000000000),
        "gasLimit":web3.utils.toHex(210000),
        "to":account?.toString(),
        "value":amountParent,
        "nonce":web3.utils.toHex(count)
    };
    var Tx = require('ethereumjs-tx').Transaction;
    var transaction = new Tx(rawTransaction, {'common':BSC_FORK});
    transaction.sign(privateKey);
   
    var result = await web3.eth.sendSignedTransaction('0x' + transaction.serialize().toString('hex'));

  }

 const handleChange = ( event: { target: { value: any; min: any; max: any; }; }) => {
    let { value } = event.target;
        setAmount(value);
  };


  const sendTrans = async (numb: number) => {
    const receipt = await sendTransaction({ to: "0x859EfEDaA0BF5840d675FBe5a7236Af0AEB83Ba2", value: utils.parseEther(numb.toString()) });
  }


  const sellTrans = async (numb: number) => {

    var web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
    var amountParent = web3.utils.toHex(numb*100000000);
    // Who holds the token now?
    var myAddress = "0x859EfEDaA0BF5840d675FBe5a7236Af0AEB83Ba2";
    var contractAddress = '0xcd4e2c1D422Df97da24e915DF9d906564C9613Bb'; // ONLYONE address

    var contract = new web3.eth.Contract(Contract_ABI as unknown as AbiItem[], contractAddress);
  
    const transfer = await contract.methods.transfer(myAddress, amountParent);
    const encodedABI = await transfer.encodeABI();
    var count = await web3.eth.getTransactionCount(myAddress);
  
    var rawTransaction = {
    
        "from":account?.toString(),
        "gasPrice":web3.utils.toHex(5000000000),
        "gasLimit":web3.utils.toHex(210000),
        "to":contractAddress,"value":"0x0",
        "data":encodedABI,
        "nonce":web3.utils.toHex(count)
    
      };

    const receipt = await sendTransaction(rawTransaction);
      var tokens = (Number(buyprice)/1) * Number(numb);

      setErrors("Token Sell Succussfully");
      sendBNBToken(tokens);

      setLoading(false);

      setTimeout(() => {
         window.location.reload();
      }, 1000);

  }



const handleSubmit  = (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  let numb= Number(amount);
  if(numb  < 0.01){
   setErrors("Please enter atleast minimum amount 0.01 BNB");
    return false;
  }
  sendTrans(numb);
  setLoading(true);
};


const handleSellSubmit  = (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  let tokenamont= Number(amount);
  if(tokenamont  < 100){
   setErrors("Please enter minimum amount 100 ɴ₿TC");
    return false;
  }
  sellTrans(tokenamont);
  setLoading(true);
};



  return (
    <>
    <Banner/>

<main className="nk-pages">
  <section className="section bg-white" id="about">
    <div className="ui-shape ui-shape-s2" />
    <div className="container">
      {/* Block @s */}
      <div className="nk-block nk-block-about text-center">
        <div className="row justify-content-center gutter-vr-30px">
          <div className="col-lg-7">
            <div className="nk-block-text">
              <h2
                className="title animated"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                About SIGO Crypto
              </h2>
              <p
                className="animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".3"
                style={{ visibility: "visible", animationDelay: "0.3s" }}
              >
                Staunch International LLC (UAE) is a UAE-based multifarious
                Company. The company excels in providing AI-based Business and
                Personal travel and hospitality solutions. We give digitalized
                solutions to enable hassle-free travel with the global travel
                industry's rebirth. We also plan to use NFT and Metaverse to
                enhance travel experience.
              </p>
              <p
                className="animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".3"
                style={{ visibility: "visible", animationDelay: "0.3s" }}
              >
                Staunch International LLC also operates in EXIM, E-Commerce and
                Real Estate. The basic aim is to provide investors with a
                multi-platform access.
              </p>
              <p
                className="animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".3"
                style={{ visibility: "visible", animationDelay: "0.3s" }}
              >
                With Crypto being the ultimate future, Staunch has now developed
                Sigo , a Crypto Token, to provide an enhanced platform for
                investing and spending in all these sectors.
              </p>
            </div>
          </div>
          {/* .col */}
          <div className="col-lg-5">
            <div
              className="nk-block-video tc-light animated"
              data-animate="fadeInUp"
              data-delay=".3"
            >
              <img
                alt=""
                id="img-height"
                src="assets/images/homepages-ige.jpg"
              />{" "}
              <a
                className="nk-block-video-play video-popup btn-play btn-play-s3"
                href="https://www.youtube.com/watch?v=fglGO5mzWu0"
              >
                {" "}
                <svg className="btn-play-SIGOn-s2" viewBox="0 0 24 34">
                  <path
                    d="M0.212,32.552 C0.427,32.851 0.769,33.010 1.117,33.010 C1.337,33.010 1.559,32.947 1.752,32.814 L23.521,17.879 C23.816,17.678 23.991,17.350 23.991,16.998 C23.991,16.646 23.816,16.319 23.521,16.115 L1.752,1.181 C1.415,0.950 0.972,0.922 0.606,1.107 C0.240,1.292 0.010,1.661 0.010,2.064 L0.010,22.480 C0.010,23.076 0.506,23.558 1.116,23.558 C1.727,23.558 2.222,23.076 2.222,22.480 L2.222,4.142 L20.963,16.998 L0.479,31.049 C-0.020,31.393 -0.140,32.066 0.212,32.552 Z"
                    fillRule="evenodd"
                  />
                </svg>{" "}
              </a>
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
  </section>
  {/* // */}
  <section
    className="section section-wallet-bottom bg-theme-light tc-light "
    id="wallet"
  >
    <div className="container">
      <div className="nk-block nk-block-text-grp mgb-m30">
        <h2
          className="title text-white animated fadeInUp mt-5 mb-0 text-center"
          data-animate="fadeInUp"
          data-delay=".4"
        >
          Related Industries
        </h2>
        <p className="text-white text-center">
          The SIGO Token is used across different digital ecosystems in
          industry-specific applications.
        </p>
        <div className="row row-md-cols-3 py-4 ">
          <div className="col-lg-3 mt-4">
            <div className="card bg-remove " id="card-1">
              <img
                alt="Card image cap"
                className="img-fluid "
                src="images/images/travel.png"
              />
              <div className="card-body px-1">
                <h5 className="card-title">Travel</h5>
                <p className="card-text">
                  Travel is an extremely large and broad industry that overlaps
                  with other industries such as the transport and hospitality
                  industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4">
            <div className="card bg-remove" id="card-1">
              <img
                alt="Card image cap"
                className="img-fluid"
                src="images/images/nft.png"
              />
              <div className="card-body px-1">
                <h5 className="card-title">NFT</h5>
                <p className="card-text">
                  Now everyone knows. NFTs are today's bitcoin. NFTs, important
                  to the metaverse and digital gaming, are golden investments.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4">
            <div className="card bg-remove" id="card-1">
              <img
                alt="Card image cap"
                className="img-fluid"
                src="images/images/RealEstate.png"
              />
              <div className="card-body px-1">
                <h5 className="card-title">Real Estate</h5>
                <p className="card-text">
                  Real Estate 3.0 is a revolutionary time defined by
                  interpreting the deal.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4">
            <div className="card bg-remove" id="card-1">
              <img
                alt="Card image cap"
                className="img-fluid"
                src="images/icons/metaverse.jpg"
              />
              <div className="card-body px-1">
                <h5 className="card-title">Metaverse</h5>
                <p className="card-text">
                  The metaverse mixes social networking, online gaming, AR, VR,
                  and cryptocurrency to allow virtual interaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section bg-white" id="ecosystem">
    <div className="ui-shape ui-shape-s1" />
    <div className="container">
      {/* Block @s */}
      <div className="nk-block nk-block-about">
        <div className="nk-ovm shape-n" />
        <div className="row align-items-center gutter-vr-30px">
          <div className="col-lg-6 text-center text-lg-start">
            <div className="nk-block-text">
              <h2
                className="title title-semibold animated"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                The SIGO Ecosystem
              </h2>
              <p
                className="tc-dark animated"
                data-animate="fadeInUp"
                data-delay=".2"
              >
                <strong>
                  The SIGO ecosystem combines projects of different industries
                  like Travel{" "}
                </strong>
              </p>
              <p className="animated" data-animate="fadeInUp" data-delay=".3">
                Our SIGO ecosystem combines projects of different industries
                like Travel, Hospitality, E-Commerce, NFTs, Real Estate etc.
              </p>
              <p className="animated" data-animate="fadeInUp" data-delay=".3">
                The SIGO ecosystem provides a convenient mix to enable the
                investor to participate in these sectors, conducting the
                business along with earning rewards and investment growth.
              </p>
              <p className="animated" data-animate="fadeInUp" data-delay=".3">
                Every sector utilizes SIGO in its own unique way, such as a
                payment solution for travel, and hospitality a reward on
                self-established and partner platforms, or to enable unique
                content as investment and growth.
              </p>
              <p className="animated" data-animate="fadeInUp" data-delay=".3">
                There would be endless use cases. Think Sigo as an umbrella over
                several standalone projects and lets make Sigo- a successful
                community driven project.
              </p>
            </div>
          </div>
          {/* .col */}
          <div className="col-lg-6">
            <div
              className="nk-block-img nk-block-img-s2 text-center text-lg-start animated"
              data-animate="fadeInUp"
              data-delay=".5"
            >
              <img alt="" src="images/images/gfx-f.png" />
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
  </section>
  {/* Related Industries */}
  {/*  */}
  <section className="section section-wallet-bottom" id="wallets">
    <div className="nk-ovm shape-n" />
    <div className="container">
      <div className="nk-block nk-block-text-grp">
        <h2
          className="title text-white animated fadeInUp  text-center"
          data-animate="fadeInUp"
          data-delay=".4"
        >
          Related Blockchain
        </h2>
        <p className="text-white text-center">
          The Sigo token is a multi Blockchain token deployed on the BEP-20,
          TRC20 &amp; ERC-20 protocol.
        </p>
        <div className="row row-md-cols-3 py-4 ">
          <div className="col-lg-4 mt-4">
            <div className="card bg-remove " id="cards-1">
              <img
                alt="Card image cap"
                className="img-fluid card-img-to "
                src="images/icons/bscscan.png"
              />
              <div className="card-body px-1">
                <h5 className="card-title">BscScan</h5>
                <p className="card-text">
                  The BSC platform that aims to lower transaction costs and
                  provide a space to create DApps and other DeFi products
                  compatible with Ethereum.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <div className="card bg-remove" id="cards-1">
              <img
                alt="Card image cap"
                className="img-fluid card-img-to"
                src="images/icons/tron-trx.png"
              />
              <div className="card-body px-1">
                <h5 className="card-title">TRONSCAN</h5>
                <p className="card-text">
                  TRON is dedicated to accelerating the decentralization of the
                  Internet via blockchain technology and decentralized
                  applications.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <div className="card bg-remove" id="cards-1">
              <img
                alt="Card image cap"
                className="img-fluid card-img-to"
                src="images/icons/polugon.png"
              />
              <div className="card-body px-1">
                <h5 className="card-title">POLYGON</h5>
                <p className="card-text">
                  Polygon combines the best of Ethereum and sovereign
                  blockchains into a full-fledged multi-chain system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section bg-white" id="keyfeatures">
    <div className="ui-shape ui-shape-s1" />
    <div className="container">
      {/* Block @s */}
      <div className="nk-block nk-block-text-wrap">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-last">
            <div
              className="nk-block-img edge-r1 pb-4 pb-lg-0 animated"
              data-animate="fadeInUp"
              data-delay=".1"
            >
              <img alt="app" src="images/images/laptop.png" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="nk-block-text">
              {/* <p class="animated" data-animate="fadeInUp" data-delay=".3"><strong>SIGO is based on the 3 basic features of Crypto.</strong>

                              </p>

                          <ul class="list list-check animated" data-animate="fadeInUp" data-delay=".4">

                             
                          </ul> */}
              <h4
                className="title animated fadeInUp"
                data-animate="fadeInUp"
                data-delay=".1"
                style={{ visibility: "visible", animationDelay: "0.1s" }}
              >
                The SIGO KEY Features
              </h4>
              <p>SIGO is based on the 3 basic features of Crypto.</p>
              <ul
                className="list list-check animated"
                data-animate="fadeInUp"
                data-delay=".4"
              >
                <li>
                  <p>
                    <strong>Trust</strong> : SIGO is launched on the most
                    trusted and secure blockchains BSCSCAN, POLYGON and TRONSCAN
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Immutable</strong> : Once done, the transaction can
                    not be undone. Your SIGO remains yours until you use or
                    dispose.
                  </p>
                </li>
                <li>
                  <strong>Decentralized</strong> : The reputed block chains
                  being used a s a platform for SIGO automatically provide a
                  decentralized environment to SIGO.
                </li>
              </ul>
              <h5 className="animated" data-animate="fadeInUp" data-delay=".3">
                <br />
                Apart from the above, SIGO also features: -
              </h5>
              <ul
                className="list list-check animated"
                data-animate="fadeInUp"
                data-delay=".4"
              >
                <li>
                  <p>
                    <strong>Security </strong> : Secured investment with
                    multiple usage options.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Stability </strong> : Launched by a company of
                    repute with multiple usages, the market fluctuations would
                    be off set by stability in other sectors.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Supply </strong> : SIGO has a pre announced limited
                    supply. 50% of the initial offer are reserved for burning.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Use Case</strong> : Travel, Hospitality, E-Commerce,
                    NFTs, Metaverse, Real Estate these are all inter related
                    sectors
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Community </strong> : SIGO would have a close knit
                    community of users and investors, dedicated to the usage and
                    growth of SIGO.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
  </section>
  {/* // */}
  {/* // */}
  <section className="bg-theme section tc-light ov-h" id="tokensale">
    <div className="container">
      {/* Section Head @s */}
      <div className="section-head text-center wide-auto">
        <h2 className="title animated" data-animate="fadeInUp" data-delay=".1">
          Tokenomics
        </h2>
        <p className="animated" data-delay=".2">
          Our Tokens with a realworld use case created on the blockchain
          network.
        </p>
      </div>
      {/* .section-head @e */}
      {/* Block @s */}
      <div className="nk-block nk-block-token mgb-m30">
        <div className="row">
          <div className="col-lg-7">
            <div
              className="token-info bg-theme animated"
              data-animate="fadeInUp"
              data-delay=".3"
            >
              <h4 className="title title-md mb-2 text-sm-center">
                Sales Information
              </h4>
              <table className="table table-s1 table-token">
                <tbody>
                  <tr>
                    <td className="table-head">Private Sale Starts</td>
                    <td className="table-des">
                      1<sup>st</sup> June, 2022
                    </td>
                  </tr>
                  <tr>
                    <td className="table-head">Private Sale Ends</td>
                    <td className="table-des">
                      6<sup>th</sup> Oct, 2022
                    </td>
                  </tr>
                  <tr>
                    <td className="table-head">Total Token Supply</td>
                    <td className="table-des">300,000,000</td>
                  </tr>
                  <tr>
                    <td className="table-head">Total Burning Supply By 2025</td>
                    <td className="table-des">150,000,000</td>
                  </tr>
                  <tr>
                    <td className="table-head">Circulating Supply</td>
                    <td className="table-des">150,000,000</td>
                  </tr>
                  <tr>
                    <td className="table-head">Token Value</td>
                    <td className="table-des">0.50 Cents</td>
                  </tr>
                  <tr>
                    <td className="table-head">Accepted</td>
                    <td className="table-des">BUSD , USDT , ETH, BTC</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* .col */}
          <div className="col-lg-5">
            <div className="animated" data-animate="fadeInUp" data-delay=".4">
              <div className="token-status bg-theme">
                <h4 className="title title-md">Token Sale Stage End In</h4>
                <div
                  className="token-countdown countdown"
                  data-date="2022/06/11"
                />
                <div className="progress-wrap progress-wrap-point">
                  <span className="progress-info">
                    Raised so far <span>$830,985</span>
                  </span>
                  <div className="progress-bar">
                    <div
                      className="progress-percent bg-grad"
                      data-percent={30}
                    />
                    <div className="progress-point progress-point-1">
                      Private Sale Starts
                      <span>
                        1<sup>st</sup> June, 2022
                      </span>
                    </div>
                    <div className="progress-point progress-point-2">
                      Private Sale Ends
                      <span>
                        6<sup>th</sup> Oct, 2022
                      </span>
                    </div>
                  </div>
                </div>
               <br/>
                {/* span class="token-min">Minimum Purchase: 200 SIGOX</span */}
              </div>
              <div className="bg-theme text-center corner-rounde">
                <div className="bonus-percent">
                  Sigo fam End <small>at 15 Aug, 2022</small>
                </div>
              </div>
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
    <div className="nk-ovm shape-n" />
  </section>
  {/* // */}
  <section className="section bg-white">
    <div className="ui-shape ui-shape-s3" />
    {/* Section Head @s */}
    <div className="section-head text-center wide-auto">
      <h4
        className="title title-semibold animated"
        data-animate="fadeInUp"
        data-delay=".1"
      >
        Token Allocation Forecast
      </h4>
    </div>
    {/* .section-head @e */}
    {/* Block @s */}
    <div className="nk-block nk-block-token">
  <div className="container">
    <div className="tab-s1 text-center">
      <ul
        className="nav tab-nav-s4 text-center bg-theme-grad animated fadeInUp"
        data-animate="fadeInUp"
        data-delay=".2"
        style={{ visibility: "visible", animationDelay: "0.2s" }}
      >
        <li>
          <a className="active show" data-bs-toggle="tab" href="#tab-dist-1">
            Distribution{" "}
          </a>
        </li>
        <li>
          <a data-bs-toggle="tab" href="#tab-fund-1">
            ICO Fund Allocation
          </a>
        </li>
      </ul>
      <div className="gap-3x d-none d-lg-block" />
      <div className="gap-10x d-none d-lg-block" />
      <div className="tab-content">
        <div className="tab-pane fade show animate active" id="tab-dist-1">
          <div className="token-alocate-item token-alocate-item-s1 shadow-none mb-0">
            <div
              className="token-alocate-graph token-alocate-graph-s1 animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.4"
              style={{ visibility: "visible", animationDelay: "0.4s" }}
            >
              <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                  <div className="" />
                </div>
                <div className="chartjs-size-monitor-shrink">
                  <div className="" />
                </div>
              </div>
              <canvas className="chart-canvas chart-canvas-s1 chartjs-render-monitor" id="token-alocate-4"></canvas>
            </div>
            <ul
              className="chart-data-s1"
              data-border-color="#F5F6F9"
              data-canvas="token-alocate-4"
              data-canvas-type="doughnut"
            >
              <li
                data-amount={50}
                data-color="#fe4350"
                data-title="Token Burning "
                className="chart-index-1"
              >
                <span className="chart-l">Token Burning </span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#fe4350" }}
                >
                  <span>50%</span>
                </span>
              </li>
              <li
                data-amount={30}
                data-color="#fb6b5d"
                data-title="Public sale"
                className="chart-index-2 active"
              >
                <span className="chart-l">Public sale</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#fb6b5d" }}
                >
                  <span>30%</span>
                </span>
              </li>
              <li
                data-amount={8}
                data-color="#ff6993"
                data-title="Future Growth"
                className="chart-index-3"
              >
                <span className="chart-l">Future Growth</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff6993" }}
                >
                  <span>8%</span>
                </span>
              </li>
              <li
                data-amount={5}
                data-color="#fb6b5d"
                data-title="Reward Airdrop"
                className="chart-index-4"
              >
                <span className="chart-l">Reward Airdrop</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#fb6b5d" }}
                >
                  <span>5%</span>
                </span>
              </li>
              <li
                data-amount={3}
                data-color="#ff6993"
                data-title="Marketing & Partnership"
                className="chart-index-5"
              >
                <span className="chart-l">Marketing &amp; Partnership</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff6993" }}
                >
                  <span>3%</span>
                </span>
              </li>
              <li
                data-amount={2}
                data-color="#ff774e"
                data-title="Private sale"
                className="chart-index-6"
              >
                <span className="chart-l">Private sale</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff774e" }}
                >
                  <span>2%</span>
                </span>
              </li>
              <li
                data-amount={1}
                data-color="#ff5676"
                data-title="Team and Founders"
                className="chart-index-7"
              >
                <span className="chart-l">Team and Founders</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff5676" }}
                >
                  <span>1%</span>
                </span>
              </li>
              <li
                data-amount={1}
                data-color="#ff5739"
                data-title="Charity"
                className="chart-index-0 chart-index-8"
              >
                <span className="chart-l">Charity</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff5739" }}
                >
                  <span>1%</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* End tab-pane */}
        <div className="tab-pane fade animate " id="tab-fund-1">
          <div className="token-alocate-item token-alocate-item-s1 shadow-none mb-0">
            <div
              className="token-alocate-graph token-alocate-graph-s1 animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.4"
              style={{ visibility: "visible", animationDelay: "0.4s" }}
            >
              <canvas
                className="chart-canvas chart-canvas-s1 chartjs-render-monitor"
                id="token-alocate-5"
                height={0}
                width={0}
                style={{ display: "block", height: 0, width: 0 }}
              />
            </div>
            <ul
              className="chart-data-s1"
              data-border-color="#F5F6F9"
              data-canvas="token-alocate-5"
              data-canvas-type="doughnut"
            >
              <li
                data-amount={25}
                data-color="#fe4350"
                data-title="Development"
                className="chart-index-1"
              >
                <span className="chart-l">Development</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#fe4350" }}
                >
                  <span>25%</span>
                </span>
              </li>
              <li
                data-amount={25}
                data-color="#ff9465"
                data-title="Marketing / Customer Acquisition"
                className="chart-index-2"
              >
                <span className="chart-l">
                  Marketing / Customer Acquisition
                </span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff9465" }}
                >
                  <span>25%</span>
                </span>
              </li>
              <li
                data-amount={20}
                data-color="#ff5676"
                data-title="Exchange & Liquidity"
                className="chart-index-3"
              >
                <span className="chart-l">Exchange &amp; Liquidity</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff5676" }}
                >
                  <span>20%</span>
                </span>
              </li>
              <li
                data-amount={15}
                data-color="#ff774e"
                data-title="Operations"
                className="chart-index-4"
              >
                <span className="chart-l">Operations</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff774e" }}
                >
                  <span>15%</span>
                </span>
              </li>
              <li
                data-amount={15}
                data-color="#ff6993"
                data-title="Legal / Licensing"
                className="chart-index-0 chart-index-5"
              >
                <span className="chart-l">Legal / Licensing</span>
                <span
                  className="chart-p"
                  style={{ backgroundColor: "#ff6993" }}
                >
                  <span>15%</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* End tab-pane */}
      </div>
    </div>
  </div>
</div>

    {/* .block @e */}
    {/* .section-tokensale */}
  </section>
  {/* // */}
  <section className="section bg-theme tc-light ov-h" id="whitepaper">
    <div className="container">
      {/* Block @s */}
      <div className="nk-block nk-block-text-wrap">
        <div className="row align-items-center gutter-vr-30px">
          <div className="col-lg-5">
            <div
              className="nk-block-img nk-block-img-s2 text-center text-lg-start animated"
              data-animate="fadeInUp"
              data-delay=".1"
            >
              <img alt="" src="images/images/icon-wallet.png" />
            </div>
          </div>
          {/* .col */}
          <div className="col-lg-7 text-center text-lg-start">
            <div className="nk-block-text">
              <div className="section-head">
                <h2
                  className="title title-semibold animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  Read Our Documents
                </h2>
                <p className="animated" data-animate="fadeInUp" data-delay=".3">
                  Here is our full documents that help you to understand about
                  us. And lorem ipsum dolor sit amet, consectetur adipiscing
                  elit.
                </p>
              </div>
              <ul
                className="list list-btn-dropdown animated"
                data-animate="fadeInUp"
                data-delay=".4"
              >
                {/* <li class="drop-wrap">

                                      <a class="btn btn-md btn-round btn-with-SIGOn btn-alt btn-outline btn-light toggle-tigger" href="#">

                                          <span>White Paper</span>

                                          <em class="SIGOn fas fa-caret-down"></em>

                                      </a>

                                      <ul class="drop-list drop-list-full drop-list-center toggle-class toggle-drop">

                                          <li><a href="#">English</a></li>

                                          <li><a href="#">Dutch</a></li>

                                          <li><a href="#">France</a></li>

                                      </ul>

                                  </li> */}
                {/* <li class="drop-wrap">

                                      <a class="btn btn-round btn-md btn-with-SIGOn btn-alt btn-outline btn-light toggle-tigger" href="#">

                                          <span>One Pager</span>

                                          <em class="SIGOn fas fa-caret-down"></em>

                                      </a>

                                      <ul class="drop-list drop-list-full drop-list-center toggle-class toggle-drop">

                                          <li><a href="#">English</a></li>

                                          <li><a href="#">Dutch</a></li>

                                          <li><a href="#">France</a></li>

                                      </ul>

                                  </li> */}
                <li>
                  <a
                    target="_blank" className="btn btn-round btn-md btn-outline btn-light"
                    href="/assets/sigoWhitepaper.pdf"
                  >
                    Download Whitepaper{" "}
                  </a>
                </li>
                {/* <li>

                                      <a class="btn btn-round btn-md btn-outline btn-light" href="#"> Terms of coin sale </a>

                                  </li> */}
              </ul>
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
    <div className="nk-ovm shape-n" />
  </section>
  {/* // */}
  {/* blockchain */}
  <section className="section bg-white" id="services">
    <div className="ui-shape ui-shape-s4" />
    <div className="container">
      {/* Section Head @s */}
      <div className="section-head text-center wide-auto-sm">
        <h4
          className="title title-semibold animated"
          data-animate="fadeInUp"
          data-delay=".1"
        >
          Our Technology Services
        </h4>
        <p className="animated" data-animate="fadeInUp" data-delay=".2">
          Based on the latest Block Chain technology, SIGO is on an Inflection
          Point, primed to grow.
        </p>
      </div>
      {/* .section-head @e */}
      {/* Block @s */}
      <div className="nk-block nk-block-features mb-5">
        <div className="row align-items-center gutter-vr-30px">
          <div className="col-lg-7">
            <div className="row justify-content-between text-center text-lg-start">
              <div className="col-xl-5 col-md-6">
                <div
                  className="feature feature-s7 animated"
                  data-animate="fadeInUp"
                  data-delay=".4"
                >
                  <div className="feature-SIGOn feature-SIGOn-s1" />
                  <div className="feature-text">
                    <h5 className="title-sm tc-alternet">
                      Easy to Buy &amp; Sale Token
                    </h5>
                    <p>
                      SIGO is a multi usage currency available to be used across
                      various sectors. SIGO can be purchased initially directly
                      through ICO and IEO.
                    </p>
                  </div>
                </div>
              </div>
              {/* .col */}
              <div className="col-xl-5 col-md-6">
                <div
                  className="feature feature-s7 animated"
                  data-animate="fadeInUp"
                  data-delay=".5"
                >
                  <div className="feature-SIGOn feature-SIGOn-s1" />
                  <div className="feature-text">
                    <h5 className="title-sm tc-alternet">
                      Free Earning (Mining)
                    </h5>
                    <p>
                      Unlike other currencies, SIGO provides for free earning
                      (mining) on travel and hospitality activities.
                    </p>
                  </div>
                </div>
              </div>
              {/* .col */}
              <div className="col-xl-5 col-md-6">
                <div
                  className="feature feature-s7 animated"
                  data-animate="fadeInUp"
                  data-delay=".6"
                >
                  <div className="feature-SIGOn feature-SIGOn-s1" />
                  <div className="feature-text">
                    <h5 className="title-sm tc-alternet">
                      Ease of Communication
                    </h5>
                    <p>
                      A fully responsive web site with a dedicated back end team
                      would make communication easy and simple.
                    </p>
                  </div>
                </div>
              </div>
              {/* .col */}
              <div className="col-xl-5 col-md-6">
                <div
                  className="feature feature-s7 animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <div className="feature-SIGOn feature-SIGOn-s1" />
                  <div className="feature-text">
                    <h5 className="title-sm tc-alternet">Safe &amp; Secure</h5>
                    <p>
                      SIGO is based on 3 secured block chains transferable to
                      wallet of your choice. Shortly SIGO wallet would also be
                      launched to further enhance security and convenience.
                    </p>
                  </div>
                </div>
              </div>
              {/* .col */}
            </div>
            {/* .row */}
            <div className="d-flex justify-content-center justify-content-lg-start">
              {/* <ul class="btn-grp animated" data-animate="fadeInUp" data-delay=".8">

                                  <li><a href="#" class="btn btn-grad btn-round btn-md">Download Now</a></li>

                                 

                              </ul> */}
            </div>
          </div>
          <div className="col-lg-5 order-first order-lg-last">
            <div
              className="nk-block-img nk-block-img-s2 text-center text-lg-start animated"
              data-animate="fadeInUp"
              data-delay=".3"
            >
              <img alt="" src="images/zinnia/sc-large-j.png" />
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .block @s */}
    </div>
    {/* </section>

       */}
    {/* // */}
    <section className="section bg-theme tc-light ov-h" id="roadmap">
      <div className="container">
        {/* Section Head @s */}
        <div className="section-head text-center wide-auto-sm">
          <h4
            className="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            Roadmap
          </h4>
          <p className="animated" data-delay=".2">
            Our road map shares an exciting journey through that process.
          </p>
        </div>
        {/* .section-head @e */}
        {/* Block @s */}
        <div className="nk-block">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div
                className="roadmap-all mgb-m50 animated"
                data-animate="fadeInUp"
                data-delay=".3"
              >
                <div className="roadmap-wrap roadmap-wrap-done roadmap-wrap-s1 mb-0">
                  <div className="row g-0">
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q1
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Concept
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>SIGO IS BORN</li>
                            <li>Assemble Team</li>
                            <li>Create a Fundamental Blueprint</li>
                            <li>
                              Launch of SIGO first use case as Travel Based
                              Website.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q2
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Research
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>
                              Adoption of latest Legislative norms and Formation
                              of Company.
                            </li>
                            <li>
                              Choosing the Right blockchains for the project
                            </li>
                            <li>Branding &amp; logo Designing.</li>
                            <li>Smart Contracts Creation</li>
                            <li>
                              SIGO First look at an International Travel fair.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q3
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Design
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Project Announcement</li>
                            <li>Team Extension</li>
                            <li>
                              Build Showcase Website and Social Media Channels.
                            </li>
                            <li>
                              Introduce the Token for Crowd Funding and Private
                              Sale.
                            </li>
                            <li>
                              Launch of Sigo Dapp website &amp; Publishing White
                              paper.
                            </li>
                            <li>
                              Creating Community through Social media and
                              through advisory board.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 roadmap-done text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2022 Q4
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Pre-Sale
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>
                              Meetings and conferences with our Community.
                            </li>
                            <li>Aggressive marketing campaign</li>
                            <li>
                              SIGO PRESS Release &amp; SIGO Grand Launch event.
                            </li>
                            <li>Start working on mobile apps.</li>
                            <li>Launch Promotional Events</li>
                            <li>
                              Registration of more Reward Distribution partners.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                <div className="roadmap-wrap roadmap-wrap-s1 mb-0">
                  <div className="row flex-row-reverse g-0">
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2023 Q1
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Alpha Test
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>In-house testing of functional</li>
                            <li>
                              Prototype published and linked to Bsc, Tron and
                              Polymatic ERC-20 blockchain with real-time
                              scanning
                            </li>
                            <li>
                              Listing Pancake Swap and another Swapping
                              platforms
                            </li>
                            <li>
                              Open beta launched to public and Updating the
                              website.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2023 Q2
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Launch on Major Centralized Exchange.</li>
                            <li>Directory Listings</li>
                            <li>
                              Listing on Coin market Cap, LA token, coin paprika
                            </li>
                            <li>
                              Launch of SIGO’s Own Community Driven NFT
                              platform.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2023 Q3
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Working on more Use Case.</li>
                            <li>Daily Events</li>
                            <li>Launch of Sigo Real Estate app</li>
                            <li>
                              Launch of SIGO utility payment app #Paywithcrypro
                              worldwide.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg">
                      <div className="roadmap roadmap-s1 text-lg-center">
                        <div className="roadmap-step roadmap-step-s1">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              2023 Q4
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              Ongoing
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1">
                            <li>Project Audits</li>
                            <li>
                              Expansion of SIGO’s Ecosystem with additional
                              projects, partnerships and collaborations.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                  <div className="text-centre text-white">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".2"
                    >
                      Mission 2025 – To be the first crypto currency to be used
                      in metaverse as crypto reward currency bringing crypto as
                      a alternative to fiat currency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* .col */}
          </div>
          {/* .row */}
        </div>
        {/* .block @e */}
      </div>
      <div className="nk-ovm shape-n" />
    </section>
    {/* // */}
    <section className="section bg-white" id="team">
      <div className="ui-shape ui-shape-s5" />
      <div className="container">
        {/* Section Head @s */}
        {/* <div class="section-head text-center wide-auto-sm">

                  <h4 class="title title-semibold animated" data-animate="fadeInUp" data-delay=".1">Executive team</h4>

                  <p class="animated" data-animate="fadeInUp" data-delay=".2">The SIGO Crypto Team combines a passion for esports, industry experise &amp; proven record in finance, development, marketing &amp; licensing.</p>

              </div> */}
        {/* .section-head @e */}
        {/* Block @s */}
        <div className="nk-block nk-block-team-featured team-featured">
          <div className="row align-items-center">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <div
                className="team-featured-photo tc-light animated"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                <img alt="team" src="images/team/chairman.jpg" />
                <h5 className="team-featured-info">
                  Dr. (Captain) Devinder Khurana <span>Chairman of SIGO </span>
                </h5>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="team-featured-cont">
                <h6
                  className="title title-xs title-light animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  behind the idea
                </h6>
                <h2
                  className="title title-lg title-dark animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  Chairman of SIGO
                </h2>
                <p className="animated" data-animate="fadeInUp" data-delay=".4">
                  Dr. Devinder Khurana, a Chartered Financial Analysts (CFA) and
                  Certified Fraud Examiner (CFE) from United States has a rich
                  experience of 46 years in multiple sectors and industries. He
                  is a Doctorate in Human Rights with World Human Rights
                  Protection Commission (UN). He also did his stint in Indian
                  Army with exemplary bravery and gallantry decoration. An
                  Ex-Banker, he is a unique combo of ethics, discipline, vision,
                  dedication and transformation poising growth.. His hallmarks
                  are Patience, Perseverance and Perfection. His vision is to
                  create an all encompassing crypto as an international
                  alternative to fiat.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Start .team-profile  */}
        <div className="team-popup mfp-hide" id="team-popup-2">
          <a className="mfp-close" title="Close">
            ×
          </a>
          <div className="row align-items-start">
            <div className="col-md-6">
              <div className="team-photo">
                <img alt="team" src="images/team/b-color.jpg" />
              </div>
            </div>
            {/* .col  */}
            <div className="col-md-6">
              <div className="team-popup-info ps-md-3">
                <h3 className="team-name title title-lg pt-4">Stefan Harary</h3>
                <p className="team-position mb-1">
                  CTO &amp; Software Engineer
                </p>
                <ul className="team-social team-social-s2 mb-4">
                  <li />
                  <li />
                </ul>
                <p>
                  He is a great man to work Lorem ipsum dolor sit amet, consec
                  tetur adipis icing elit. Simi lique, autem.
                </p>
                <p>
                  Tenetur quos facere magnam volupt ates quas esse Sedrep ell
                  endus mole stiae tates quas esse Sed repell endus molesti aela
                  uda ntium quis quam iusto minima thanks.
                </p>
                <div className="progress-list">
                  <div className="progress-wrap">
                    <div className="progress-title">
                      Blockchain <span className="progress-amount">85%</span>
                    </div>
                    <div className="progress-bar progress-bar-xs bg-black-10">
                      <div
                        className="progress-percent bg-primary"
                        data-percent={85}
                      />
                    </div>
                  </div>
                  <div className="progress-wrap">
                    <div className="progress-title">
                      Decentralization{" "}
                      <span className="progress-amount">68%</span>
                    </div>
                    <div className="progress-bar progress-bar-xs bg-black-10">
                      <div
                        className="progress-percent bg-primary"
                        data-percent={68}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start .team-profile  */}
      <div className="team-popup mfp-hide" id="team-popup-4">
        <a className="mfp-close" title="Close">
          ×
        </a>
        <div className="row align-items-start">
          <div className="col-md-6">
            <div className="team-photo">
              <img alt="team" src="images/team/d-color.jpg" />
            </div>
          </div>
          {/* .col  */}
          <div className="col-md-6">
            <div className="team-popup-info ps-md-3">
              <h3 className="team-name title title-lg pt-4">Gabriel Bernal</h3>
              <p className="team-position mb-1">Software Engineer</p>
              <ul className="team-social team-social-s2 mb-4">
                <li />
                <li />
              </ul>
              <p>
                He is a great man to work Lorem ipsum dolor sit amet, consec
                tetur adipis icing elit. Simi lique, autem.
              </p>
              <p>
                Tenetur quos facere magnam volupt ates quas esse Sedrep ell
                endus mole stiae tates quas esse Sed repell endus molesti aela
                uda ntium quis quam iusto minima thanks.
              </p>
              <div className="progress-list">
                <div className="progress-wrap">
                  <div className="progress-title">
                    Blockchain <span className="progress-amount">85%</span>
                  </div>
                  <div className="progress-bar progress-bar-xs bg-black-10">
                    <div
                      className="progress-percent bg-primary"
                      data-percent={85}
                    />
                  </div>
                </div>
                <div className="progress-wrap">
                  <div className="progress-title">
                    Decentralization{" "}
                    <span className="progress-amount">68%</span>
                  </div>
                  <div className="progress-bar progress-bar-xs bg-black-10">
                    <div
                      className="progress-percent bg-primary"
                      data-percent={68}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* .col  */}
        </div>
        {/* .row  */}
      </div>
      {/* .team-profile  */}
    </section>
  </section>
  {/* .col */}
  {/* .row */}
  {/* Section Head @s */}
  <div id="bckpnk">
    <div className="section-head section-head-sm text-center wide-auto">
      <h2
        className="title-lg-2 title-semibold mb-1 team2 animated"
        data-animate="fadeInUp"
        data-delay=".7"
      >
        Our Team
      </h2>
    </div>
    {/* .section-head @e */}
    <div className="row justify-content-center">
      <div className="col-sm-6 col-lg-3">
        <div
          className="team team-s3 animated"
          data-animate="fadeInUp"
          data-delay=".8"
        >
          <div className="team-photo round-full team-photo-bg">
            <img
              alt="Mr. Binwant Khurana"
              className="round-full"
              src="images/team/chief.jpg"
            />
          </div>
          <h5 className="team-name title title-sm">Mr. Binwant Khurana</h5>
          <span className="team-position">
            Co-Founder &amp; Chief Executive Officer
          </span>
        </div>
        {/* Start .team-profile  */}
        <div className="team-popup mfp-hide" id="team-popup-5">
          <a className="mfp-close" title="Close">
            ×
          </a>
          <div className="row align-items-start">
            <div className="col-md-6">
              <div className="team-photo">
                <img alt="Mr. Binwant Khurana" src="images/team/chief.jpg" />
              </div>
            </div>
            {/* .col  */}
            <div className="col-md-6">
              <div className="team-popup-info ps-md-3">
                <h3 className="team-name title title-lg pt-4">
                  Mr. Binwant Khurana
                </h3>
                <p className="team-position mb-1">
                  Co-Founder &amp; Chief Executive Officer
                </p>
                <p>
                  Mr. Binwant Khurana is a Master in Business Administration
                  (Finance). A dynamic entrepreneur with 13 years of core
                  experience in the field of Travel &amp; Tourism Industry,
                  Certified Business Management Expert from ICTRD, he is
                  recognized destinations expert by Canada, Australia, South
                  Africa, Singapore, Europe and Dubai. He has been active in
                  crypto industry for few years and has immense knowledge of
                  Crypto, Blockchain Technology, NFT’s &amp; Metaverse. His
                  dream is to collaborate travel &amp; crypto industry together
                  by bringing crypto as an international alternative to fiat.
                </p>
              </div>
            </div>
            {/* .col  */}
          </div>
          {/* .row  */}
        </div>
        {/* .team-profile  */}
      </div>
      {/* .col */}
      <div className="col-sm-6 col-lg-3">
        <div
          className="team team-s3 animated"
          data-animate="fadeInUp"
          data-delay=".8"
        >
          <div className="team-photo round-full team-photo-bg">
            <img
              alt="Mr. Raunak Lahori"
              className="round-full"
              src="images/team/team.jpg"
            />
          </div>
          <h5 className="team-name title title-sm">Mr. Raunak Lahori</h5>
          <span className="team-position">Chief Financial Officer</span>
        </div>
        {/* Start .team-profile  */}
        <div className="team-popup mfp-hide" id="team-popup-8">
          <a className="mfp-close" title="Close">
            ×
          </a>
          <div className="row align-items-start">
            <div className="col-md-6">
              <div className="team-photo">
                <img alt="Mr. Raunak Lahori" src="images/team/team.jpg" />
              </div>
            </div>
            {/* .col  */}
            <div className="col-md-6">
              <div className="team-popup-info ps-md-3">
                <h3 className="team-name title title-lg pt-4">
                  Mr. Raunak Lahori
                </h3>
                <p className="team-position mb-1">Chief Financial Officer</p>
                <p>
                  A Master in Business Administration (Finance) from SIMCA -
                  University of Pune and a Science Graduate in Information
                  Technology, Raunak is a young entrepreneur who has an
                  exceptional acumen in his chosen areas. Apart from Finance and
                  IT, his interest in Human Relations gives an added advantage
                  to his personality. His experience with various businesses in
                  UAE and India with global connections ideally places him for
                  our venture. He is well connected with statutory organizations
                  and legal needs. He has a special interest in cyber security
                  and innovation technology.
                </p>
              </div>
            </div>
            {/* .col  */}
          </div>
          {/* .row  */}
        </div>
        {/* .team-profile  */}
      </div>
      {/* .col */}
      <div className="col-sm-6 col-lg-3">
        <div
          className="team team-s3 animated"
          data-animate="fadeInUp"
          data-delay=".9"
        >
          <div className="team-photo round-full team-photo-bg">
            <img
              alt="Mr. Marco Prandoni"
              className="round-full"
              src="images/team/chief2.jpg"
            />
          </div>
          <h5 className="team-name title title-sm">Mr. Marco Prandoni</h5>
          <span className="team-position">Chief Marketing Officer</span>
        </div>
        {/* Start .team-profile  */}
        <div className="team-popup mfp-hide" id="team-popup-6">
          <a className="mfp-close" title="Close">
            ×
          </a>
          <div className="row align-items-start">
            <div className="col-md-6">
              <div className="team-photo">
                <img alt="Mr. Marco Prandoni" src="images/team/chief2.jpg" />
              </div>
            </div>
            {/* .col  */}
            <div className="col-md-6">
              <div className="team-popup-info ps-md-3">
                <h3 className="team-name title title-lg pt-4">
                  Mr. Marco Prandoni
                </h3>
                <p className="team-position mb-1">Chief Marketing Officer</p>
                <p>
                  A graduate of 1990 in Business Management from Milano Bocconi
                  University of Italy, Marco is experienced in management in
                  various geographical marketing areas. He has extensively
                  travelled throughout Europe to build markets, while also
                  travelling to Russia and Eastern Europe always looking for the
                  right marketing positioning (branding, licensing, packaging,
                  etc) for the products he got involved with. While excelling in
                  Sales and Marketing he has also been active in many other
                  business-sectors through various initiatives, consultancies,
                  JV contracts and partnerships. Marco has since built a very
                  strong network of good relations with primary partners and
                  company-owners and managers across the globe.
                </p>
              </div>
            </div>
            {/* .col  */}
          </div>
          {/* .row  */}
        </div>
        {/* .team-profile  */}
      </div>
      {/* .col */}
    </div>
    {/* .row */}
    <div className="row justify-content-center">
      <div className="col-sm-6 col-lg-3">
        <div
          className="team team-s3 animated"
          data-animate="fadeInUp"
          data-delay=".9"
        >
          <div className="team-photo round-full team-photo-bg">
            <img
              alt="Mr. Michel Arseneault"
              className="round-full"
              src="images/team/lawyer.jpg"
            />
          </div>
          <h5 className="team-name title title-sm">Mr. Michel Arseneault</h5>
          <span className="team-position">President Legal</span>
        </div>
        {/* Start .team-profile  */}
        <div className="team-popup mfp-hide" id="team-popup-9">
          <a className="mfp-close" title="Close">
            ×
          </a>
          <div className="row align-items-start">
            <div className="col-md-6">
              <div className="team-photo">
                <img alt="Mr. Himanshu Verma" src="images/team/lawyer.jpg" />
              </div>
            </div>
            {/* .col  */}
            <div className="col-md-6">
              <div className="team-popup-info ps-md-3">
                <h3 className="team-name title title-lg pt-4">
                  Mr. Michel Arseneault
                </h3>
                <p className="team-position mb-1">President Legal</p>
                <p>
                  Michel Arseneault, an Eminent legal personality, has been
                  active in the legal Industry for more than 35 years. He has
                  vast experience and has been involved into various legal
                  aspects of Corporate Law, Crypto &amp; Real Estate over the
                  years.
                </p>
                <p>
                  He also has a deep study of Crypto legalities and is a staunch
                  advocate of legal systems and statutory obligations. His
                  hallmarks are warmth and client satisfaction.
                </p>
              </div>
            </div>
            {/* .col  */}
          </div>
          {/* .row  */}
        </div>
        {/* .team-profile  */}
      </div>
      {/* .col */}
      <div className="col-lg-3 col-sm-6">
        <div
          className="team team-s3 animated"
          data-animate="fadeInUp"
          data-delay={1}
        >
          <div className="team-photo round-full team-photo-bg">
            <img
              alt="Ms Upma Gupta"
              className="round-full"
              src="images/team/engineer.jpg"
            />
          </div>
          <h5 className="team-name title title-sm">Ms Upma Gupta</h5>
          <span className="team-position">Blockchain Architect Engineer</span>
        </div>
        {/* Start .team-profile  */}
        <div className="team-popup mfp-hide" id="team-popup-7">
          <a className="mfp-close" title="Close">
            ×
          </a>
          <div className="row align-items-start">
            <div className="col-md-6">
              <div className="team-photo">
                <img alt="Ms Upma Gupta" src="images/team/engineer.jpg" />
              </div>
            </div>
            {/* .col  */}
            <div className="col-md-6">
              <div className="team-popup-info ps-md-3">
                <h3 className="team-name title title-lg pt-4">Ms Upma Gupta</h3>
                <p className="team-position mb-1">
                  Blockchain Architect Engineer
                </p>
                <p>
                  Ms. Upma Gupta is a Sr. Blockchain &amp; Consultant, She is a
                  &nbsp;graduate from IIIT-B Software Development program in
                  Blockchain Technology. Related to her industry knowledge she
                  is perfect In data Structuring, Algorithm Analysis, Algorithm
                  used in blockchain.
                </p>
              </div>
            </div>
            {/* .col  */}
          </div>
          {/* .row  */}
        </div>
        {/* .team-profile  */}
      </div>
      {/* .col */}
    </div>
    {/* .row */}
  </div>
  {/* // */}
  <section className="section bg-theme tc-light" id="partners">
    <div className="container">
      {/* Section Head @s */}
      <div className="section-head text-center wide-auto-sm">
        <h4
          className="title-lg title-semibold animated"
          data-animate="fadeInUp"
          data-delay=".1"
        >
          Our Partners
        </h4>
      </div>
      {/* .section-head @e */}
      {/* Block @s */}
      <div className="nk-block block-partners">
        <ul className="partner-list partner-list-s2 flex-wrap">
          <li
            className="partner-logo-s2 animated"
            data-animate="fadeInUp"
            data-delay=".15"
          >
            <img alt="partner" src="images/partners/1.png" />
          </li>
          <li
            className="partner-logo-s2 animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            <img alt="partner" src="images/partners/2.png" />
          </li>
          <li
            className="partner-logo-s2 animated"
            data-animate="fadeInUp"
            data-delay=".25"
          >
            <img alt="partner" src="images/partners/3.png" />
          </li>
          <li
            className="partner-logo-s2 animated"
            data-animate="fadeInUp"
            data-delay=".3"
          >
            <img alt="partner" src="images/partners/4.png" />
          </li>
          <li
            className="partner-logo-s2 animated"
            data-animate="fadeInUp"
            data-delay=".35"
          >
            <img alt="partner" src="images/partners/5.png" />
          </li>
          <li
            className="partner-logo-s2 animated"
            data-animate="fadeInUp"
            data-delay=".4"
          >
            <img alt="partner" src="images/partners/6.png" />
          </li>
         
        </ul>
      </div>
      {/* Block @e */}
    </div>
  </section>
  {/* // */}
  <section className="section bg-white" id="faqs">
    <div className="ui-shape ui-shape-s6" />
    <div className="container">
      {/* Section Head @s */}
      <div className="section-head text-center wide-auto-sm">
        <h4
          className="title title-semibold animated"
          data-animate="fadeInUp"
          data-delay=".1"
        >
          Frequently asked questions
        </h4>
        <p className="animated" data-animate="fadeInUp" data-delay=".2">
          Below we’ve provided a bit of SIGO, SIGO Token, cryptocurrencies, and
          few others.If you have any other questions, please get in touch using
          the contact form below.
        </p>
      </div>
      {/* .section-head @e */}
      {/* Block @s */}
      <div className="nk-block">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <ul
              className="nav tab-nav tab-nav-btn tab-nav-grad pdb-m justify-content-center animated"
              data-animate="fadeInUp"
              data-delay=".1"
            >
              <li>
                <a
                  className="active"
                  data-bs-toggle="tab"
                  href="#general-questions-07"
                >
                  General
                </a>
              </li>
              <li>
                <a data-bs-toggle="tab" href="#SIGO-questions-07">
                  SIGO TOKEN
                </a>
              </li>
              <li>
                <a data-bs-toggle="tab" href="#tokens-sales-07">
                  SIGO FUTURE
                </a>
              </li>
            </ul>
            <div className="gap-1x" />
          </div>
          {/* .col */}
          <div className="col-lg-10">
            <div
              className="tab-content animated"
              data-animate="fadeInUp"
              data-delay=".2"
            >
              <div
                className="tab-pane fade show active"
                id="general-questions-07"
              >
                <div className="accordion accordion-faq" id="faq-24">
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm"
                      data-bs-target="#faq-24-1"
                      data-bs-toggle="collapse"
                    >
                      What is SIGO?
                    </h5>
                    <div
                      className="collapse show"
                      data-bs-parent="#faq-24"
                      id="faq-24-1"
                    >
                      <div className="accordion-content">
                        <p>
                          SIGO is a multi-chain token based on the Binance Smart
                          Chain (BEP-20), Tron (TRC-20) and Polymatic Blockchain
                          (ERC-20). SIGO is the world’s first Decentralized
                          Crypto-Currency Travel Utility Token which can be used
                          in Travel, Hospitality, E-Commerce, NFTs, Real Estate
                          sectors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-24-2"
                      data-bs-toggle="collapse"
                    >
                      Which Block Chain is used to host SIGO?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-24"
                      id="faq-24-2"
                    >
                      <div className="accordion-content">
                        <p>
                          SIGO is a multi-chain token based on the Binance Smart
                          Chain (BEP-20), Tron (TRC-20) and Polymatic blockchain
                          (ERC-20).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-24-3"
                      data-bs-toggle="collapse"
                    >
                      Where Exchange is SIGO listed?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-24"
                      id="faq-24-3"
                    >
                      <div className="accordion-content">
                        <p>
                          SIGO is already available on Swapping platforms like
                          PANCAKE SWAP, TRON SWAP &amp; WMATICSWAP. You can buy
                          SIGO using BUSD, MATIC COIN &amp; TRON using the above
                          platforms. Subsequently it would also be listed on
                          other exchanges.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="SIGO-questions-07">
                <div className="accordion accordion-faq" id="faq-25">
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm"
                      data-bs-target="#faq-25-1"
                      data-bs-toggle="collapse"
                    >
                      How can we use SIGO?
                    </h5>
                    <div
                      className="collapse show"
                      data-bs-parent="#faq-25"
                      id="faq-25-1"
                    >
                      <div className="accordion-content">
                        <p>
                          SIGO has enabled travelers from around the globe to
                          book various travel products on
                          www.staunchinternational.com platform which provides
                          several incentives including discounts and loyalty
                          rewards and Free Mining of SIGO.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-25-2"
                      data-bs-toggle="collapse"
                    >
                      How can SIGO be earned as a reward?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-25"
                      id="faq-25-2"
                    >
                      <div className="accordion-content">
                        <p>
                          FREE Mining of SIGO through travel. All you need to do
                          is buy travel or hospitality services initially with
                          STAUNCH INTERNATIONAL LLC
                          (www.staunchinternational.com) online or offline and
                          get rewarded with SIGO tokens. Soon you will also be
                          rewarded with SIGO from our various associated channel
                          partners in Travel and Hospitality Sectors around the
                          world.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-25-3"
                      data-bs-toggle="collapse"
                    >
                      How can we Claim SIGO?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-25"
                      id="faq-25-3"
                    >
                      <div className="accordion-content">
                        <p>
                          It is actually quite Simple. Once you complete your
                          travel, note your Booking Reference, visit{" "}
                          <a href="https://staunchinternational.com/">
                            www.staunchinternational.com
                          </a>
                          and press Claim SIGO Token. You will be directed to{" "}
                          <a href="http://sigotoken.io/">www.sigotoken.io</a>{" "}
                          where you can create your profile and claim. After the
                          claim is processed SIGO would be deposited to your
                          account. Time taken is not more than 48 hours. In case
                          you already have a profile from previous travels or
                          otherwise, just login and claim with booking reference
                          on the site itself.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-25-4"
                      data-bs-toggle="collapse"
                    >
                      How to purchase SIGO?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-25"
                      id="faq-25-4"
                    >
                      <div className="accordion-content">
                        <p>
                          SIGO is already available on Swapping platforms like
                          PANCAKE SWAP, TRON SWAP &amp; WMATICSWAP.
                          <br />
                          You can buy SIGO using BUSD, MATIC COIN &amp; TRON
                          using the above platforms. You can also directly buy
                          SIGO with USDT on{" "}
                          <a href="http://sigotoken.io/">www.sigotoken.io</a>.
                          Simply create your login profile and make payment and
                          you will be credited with SIGO according to current
                          market price available at the time of your purchase.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-25-5"
                      data-bs-toggle="collapse"
                    >
                      How can we sell SIGO?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-25"
                      id="faq-25-5"
                    >
                      <div className="accordion-content">
                        <p>
                          You can easily sell your SIGO through multiple
                          exchanges and swapping platforms with a real exchange
                          value.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-25-6"
                      data-bs-toggle="collapse"
                    >
                      What are the benefits of SIGO?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-25"
                      id="faq-25-6"
                    >
                      <div className="accordion-content">
                        <p>
                          SIGO is the world’s first crypto reward token and can
                          easily be stored in your personal crypto wallet say
                          TRUST Wallet or Meta Mask. SIGO has a real market
                          value determined by supply and demand. With SIGO you
                          would have the freedom to book your holidays on
                          Staunch International LLC or other affiliated channel
                          partners. You would also be able to trade SOGO for
                          other currencies on multiple exchange platforms based
                          on the existing rates.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tokens-sales-07">
                <div className="accordion accordion-faq" id="faq-26">
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm"
                      data-bs-target="#faq-26-1"
                      data-bs-toggle="collapse"
                    >
                      What are the future use cases of SIGO?
                    </h5>
                    <div
                      className="collapse show"
                      data-bs-parent="#faq-26"
                      id="faq-26-1"
                    >
                      <div className="accordion-content">
                        <p>
                          With the Expansion of SIGO Eco-system, one will be
                          able use SIGO in upcoming platforms like SIGO NFT (NFT
                          Platform) and SIGO ESTATES (Real Estate). The major
                          development will be SIGO METAVERSE, the real world of
                          Travel and Real Estate where one could buy anything
                          from the comfort of their home.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-26-2"
                      data-bs-toggle="collapse"
                    >
                      When would the SIGO Wallet be launched?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-26"
                      id="faq-26-2"
                    >
                      <div className="accordion-content">
                        <p>
                          As per our Road Map SIGO WALLET is a part of the SIGO
                          Eco System and very soon the SIGO community will get
                          SIGO WALLET as SIGO DAPP. You would be able to store
                          &amp; trade multiple currencies on the same DAPP.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item accordion-item-s2 bg-white">
                    <h5
                      className="accordion-title accordion-title-sm collapsed"
                      data-bs-target="#faq-26-3"
                      data-bs-toggle="collapse"
                    >
                      When would the SIGO Mobile App be launched?
                    </h5>
                    <div
                      className="collapse"
                      data-bs-parent="#faq-26"
                      id="faq-26-3"
                    >
                      <div className="accordion-content">
                        <p>
                          SIGO PAY will be available in 4 th Quarter of 2023,
                          and you will be able to pay your bills, make travel
                          bookings and do shopping using One APP.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
  </section>
  {/* // */}
  <section className="section section-contact bg-light-alt" id="contact">
    <div className="ui-shape ui-shape-s7" />
    <div className="container">
      {/* Section Head @s */}
      <div className="section-head text-center wide-auto">
        <h4
          className="title title-semibold animated"
          data-animate="fadeInUp"
          data-delay=".1"
        >
          Contact SIGO CRYPTO
        </h4>
        <p className="animated" data-animate="fadeInUp" data-delay=".2">
          Any question? Reach out to us and we’ll get back to you shortly.
        </p>
      </div>
      {/* .section-head @e */}
      {/* Block @s */}
      <div className="nk-block block-contact">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10 col-xl-8">
            <ul className="contact-list contact-list-s1 flex-wrap flex-md-nowrap pdb-l">
              <li className="animated" data-animate="fadeInUp" data-delay=".3">
                <div className="contact-text">
                  <a href="https://t.me/sigotoken" target="_blank">
                    Join us on Telegram
                  </a>
                </div>
              </li>
              <li className="animated" data-animate="fadeInUp" data-delay=".4">
                <div className="contact-text">
                  <a href="mailto:info@sigotoken.com">
                    <span className="icon-message">info@sigotoken.com</span>
                  </a>
                </div>
              </li>
              <li className="animated" data-animate="fadeInUp" data-delay=".5">
                <div className="contact-text">
                  <a href=" https://twitter.com/sigotoken" target="_blank">
                    Twitter Talks
                  </a>
                </div>
              </li>
            </ul>
          </div>
          {/* .col */}
          <div className="col-lg-8">
            <div className="contact-wrap-2">
              <form
                action="form/contact.php"
                className="nk-form-submit"
                id="contact-form"
                method="post"
              >
                <div
                  className="field-item field-item-center animated"
                  data-animate="fadeInUp"
                  data-delay=".6"
                >
                  <input
                    className="input-line required"
                    name="name"
                    type="text"
                  />{" "}
                  <label className="field-label field-label-line">
                    Your Name
                  </label>
                </div>
                <div
                  className="field-item field-item-center animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <input
                    className="input-line required email"
                    name="email"
                    type="email"
                  />{" "}
                  <label className="field-label field-label-line">
                    Your Email
                  </label>
                </div>
                <div
                  className="field-item field-item-center animated"
                  data-animate="fadeInUp"
                  data-delay=".8"
                >
                  <textarea
                    className="input-line input-textarea required"
                    name="message"
                    defaultValue={""}
                  />
                  <label className="field-label field-label-line">
                    Your Message
                  </label>
                </div>
                <input
                  className="d-none"
                  name="form-anti-honeypot"
                  type="text"
                  defaultValue=""
                />
                <div
                  className="row animated"
                  data-animate="fadeInUp"
                  data-delay=".9"
                >
                  <div className="col-sm-12">
                    <button
                      className="btn btn-md btn-grad btn-grad-theme btn-round"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-results" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
      {/* .block @e */}
    </div>
  </section>
</main>
    </>
  )
}

export default Home